import {
  Checkbox,
} from "@shopify/polaris";
import _ from "lodash";
import * as PropTypes from 'prop-types'
import Select from "react-select";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import TopMenuSelectedProjects from './TopMenuSelectedProjects'
import './topbar.css'

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

const selectedCompany = localStorage?.getItem('selectedCompany');

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: selectedCompany ? Number(selectedCompany) : props.selected || "",
      filterActive: false,
      filterTrial: false,
    };
  }
  
  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (!prevProps.selected && selected && !selectedCompany) {
      this.updateSelected();
    }
  }
  
  updateSelected = () => {
    const { selected } = this.props;
    this.setState({
      selected,
    });
  };
  
  handleSelectChange = (value) => {
    this.setState({ selected: value });
  };
  
  render() {
    const { title, loadingButtonClick, manager } = this.props;
    const { selected, filterActive, filterTrial } = this.state;
    let options = [];

    if (this.props.options) {
      let filteredOptions = [...this.props.options];
      if (filterActive && filterTrial) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "active" || item.status === "trial"
        );
      } else if (filterActive) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "active"
        );
      } else if (filterTrial) {
        filteredOptions = _.filter(
          filteredOptions,
          (item) => item.status === "trial"
        );
      } else {
        filteredOptions = filteredOptions;
      }
      options = filteredOptions.map((company) => {
        return {
          label: company.name,
          value: company.id,
        };
      });
    }
    
    const menuOptions = [
      {
        label: "",
        value: "",
      },
      ...options,
    ];
    
    return (
      <div
        style={{
          padding: "10px 0",
          backgroundColor: "#ffffff",
          boxShadow: '0 1.25rem 3.75rem rgba(182, 195, 211, .5)'
        }}
      >
        <div className='Polaris-Page' style={{width: '100%', maxWidth: '100%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            {
              manager
                ?
                <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                  <p style={{marginRight: 10, fontSize: '14px', fontWeight: 'bold'}}>
                    {title}
                  </p >
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div  style={{marginRight: 10}}>
                      <Checkbox
                        label='Active'
                        checked={filterActive}
                        onChange={() =>
                          this.setState((prevState) => ({
                            filterActive: !prevState.filterActive,
                            selected: "",
                          }))
                        }
                      />
                    </div>
                    <div  style={{marginRight: 10}}>
                      <Checkbox
                        label='Trial'
                        checked={filterTrial}
                        onChange={() =>
                          this.setState((prevState) => ({
                            filterTrial: !prevState.filterTrial,
                            selected: "",
                          }))
                        }
                      />
                    </div>
                  </div >
                  <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <div style={{minWidth: "150px"}}>
                      <Select
                        id='companyId'
                        value={
                          menuOptions?.find(({value}) => value === selected) || ""
                        }
                        onChange={({value}) => {
                          this.setState({selected: value});
                          this.props.changeCompanyName(value)
                          localStorage.setItem('selectedCompany', JSON.stringify(value));
                        }}
                        options={menuOptions}
                        isLoading={loadingButtonClick}
                        styles={{
                          menu: (provided) => ({...provided, zIndex: 9999}),
                          indicatorSeparator: () => ({ display: "none" }),
                          dropdownIndicator: () => ({ display: "none" })
                        }}
                        caretDown={null}
                      />
                    </div >
                  </div >
                </div >
                :
                <div ></div >
            }
            <TopMenuSelectedProjects />
          </div >
        </div >
      </div >
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu))
);
