import React from "react";

const BillingIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.16602 3.33317C3.70578 3.33317 3.33268 3.70627 3.33268 4.1665C3.33268 4.62674 3.70578 4.99984 4.16602 4.99984L15.8327 4.99984C17.2134 4.99984 18.3327 6.11913 18.3327 7.49984V15.8332C18.3327 17.2139 17.2134 18.3332 15.8327 18.3332H4.16602C2.7853 18.3332 1.66602 17.2139 1.66602 15.8332V4.1665C1.66602 2.78579 2.7853 1.6665 4.16602 1.6665H14.166C14.6263 1.6665 14.9993 2.0396 14.9993 2.49984C14.9993 2.96007 14.6263 3.33317 14.166 3.33317H4.16602ZM13.7493 10.4165C13.059 10.4165 12.4993 10.9761 12.4993 11.6665C12.4993 12.3569 13.059 12.9165 13.7493 12.9165C14.4397 12.9165 14.9993 12.3569 14.9993 11.6665C14.9993 10.9761 14.4397 10.4165 13.7493 10.4165Z'
          fill='#0d6efd'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.12'
        d='M17.5 15.8337V7.50033C17.5 6.57985 16.7538 5.83366 15.8333 5.83366L4.16667 5.83366C3.24619 5.83366 2.5 5.08747 2.5 4.16699V15.8337C2.5 16.7541 3.24619 17.5003 4.16667 17.5003H15.8333C16.7538 17.5003 17.5 16.7541 17.5 15.8337Z'
        fill='#858585'
      />
      <path
        d='M13.75 11.6667H13.7583M2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V7.5C17.5 6.57953 16.7538 5.83333 15.8333 5.83333L4.16667 5.83333C3.24619 5.83333 2.5 5.08714 2.5 4.16667ZM2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H14.1667M14.1667 11.6667C14.1667 11.8968 13.9801 12.0833 13.75 12.0833C13.5199 12.0833 13.3333 11.8968 13.3333 11.6667C13.3333 11.4365 13.5199 11.25 13.75 11.25C13.9801 11.25 14.1667 11.4365 14.1667 11.6667Z'
        stroke='#858585'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BillingIcon;
