import React from "react";

const SalesTaxReturnsIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.33398 18.3332H12.6673C14.0674 18.3332 14.7675 18.3332 15.3023 18.0607C15.7727 17.821 16.1552 17.4386 16.3948 16.9681C16.6673 16.4334 16.6673 15.7333 16.6673 14.3332V5.6665C16.6673 4.26637 16.6673 3.56631 16.3948 3.03153C16.1552 2.56112 15.7727 2.17867 15.3023 1.93899C14.7675 1.6665 14.0674 1.6665 12.6673 1.6665H12.2507C11.5826 1.6665 11.2485 1.6665 10.949 1.76317C10.6839 1.8487 10.4397 1.98864 10.2318 2.17403C9.99698 2.38354 9.82803 2.67176 9.49012 3.2482L3.62899 13.2466C3.60875 13.2811 3.59863 13.2984 3.58901 13.3156C3.42831 13.6025 3.34085 13.9246 3.33437 14.2535C3.33398 14.2731 3.33398 14.2932 3.33398 14.3332C3.33398 15.7333 3.33398 16.4334 3.60647 16.9681C3.84615 17.4386 4.2286 17.821 4.69901 18.0607C5.23379 18.3332 5.93385 18.3332 7.33398 18.3332Z'
          fill='#0d6efd'
        />
        <path
          d='M7.89053 5.70872L7.88163 5.70644L7.89053 5.70872ZM12.1926 5.67062L11.8026 4.92803C11.6644 4.66484 11.3916 4.5 11.0943 4.5H10.7826C10.1662 4.5 9.78133 5.16782 10.0904 5.70116L11.0609 7.37558L10.2371 8.78026L9.0259 5.05278C8.9188 4.72317 8.61163 4.5 8.26506 4.5H7.58118C7.23341 4.5 6.92548 4.72467 6.81934 5.05585L5.46974 9.26711C5.30429 9.78339 5.68943 10.3113 6.23157 10.3113H6.50455C6.85684 10.3113 7.16767 10.0808 7.27003 9.74371L7.51171 8.94783H8.29628L8.55396 9.75465C8.65985 10.0862 8.96797 10.3113 9.31603 10.3113H9.6334C9.85074 10.3113 10.0428 10.2265 10.1843 10.0916C10.3243 10.2258 10.5152 10.3113 10.7358 10.3113H11.0331C11.3361 10.3113 11.6131 10.1401 11.7487 9.86903L12.1423 9.08176C12.1494 9.06806 12.1564 9.0545 12.1634 9.04106C12.1732 9.05871 12.1832 9.07661 12.1933 9.09478L12.6205 9.88992C12.76 10.1494 13.0307 10.3113 13.3252 10.3113H13.6394C14.2598 10.3113 14.6441 9.63587 14.3272 9.1026L13.2855 7.34951L14.2666 5.71098C14.5859 5.17775 14.2018 4.5 13.5803 4.5H13.2855C12.9866 4.5 12.7126 4.66663 12.5752 4.93205L12.1926 5.67062ZM3.37057 9.51126C3.37057 9.95309 3.72874 10.3113 4.17057 10.3113H4.44859C4.89041 10.3113 5.24859 9.95309 5.24859 9.51126V6.23525H5.83343C6.27526 6.23525 6.63343 5.87708 6.63343 5.43525V5.3C6.63343 4.85817 6.27526 4.5 5.83343 4.5H2.8C2.35817 4.5 2 4.85817 2 5.3V5.43525C2 5.87708 2.35817 6.23525 2.8 6.23525H3.37057V9.51126Z'
          fill='black'
          stroke='white'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.12'
        d='M7.3335 18.3337H12.6668C14.067 18.3337 14.767 18.3337 15.3018 18.0612C15.7722 17.8215 16.1547 17.439 16.3943 16.9686C16.6668 16.4339 16.6668 15.7338 16.6668 14.3337V5.66699C16.6668 4.26686 16.6668 3.5668 16.3943 3.03202C16.1547 2.56161 15.7722 2.17916 15.3018 1.93948C14.767 1.66699 14.067 1.66699 12.6668 1.66699H10.4168L3.3335 13.7503V14.3337C3.3335 15.7338 3.3335 16.4339 3.60598 16.9686C3.84566 17.439 4.22811 17.8215 4.69852 18.0612C5.2333 18.3337 5.93336 18.3337 7.3335 18.3337Z'
        fill='#707070'
      />
      <path
        d='M10.4168 1.66699H12.6668C14.067 1.66699 14.767 1.66699 15.3018 1.93948C15.7722 2.17916 16.1547 2.56161 16.3943 3.03202C16.6668 3.5668 16.6668 4.26686 16.6668 5.66699V14.3337C16.6668 15.7338 16.6668 16.4339 16.3943 16.9686C16.1547 17.439 15.7722 17.8215 15.3018 18.0612C14.767 18.3337 14.067 18.3337 12.6668 18.3337H7.3335C5.93336 18.3337 5.2333 18.3337 4.69852 18.0612C4.22811 17.8215 3.84566 17.439 3.60598 16.9686C3.3335 16.4339 3.3335 15.7338 3.3335 14.3337V13.7503'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.87057 9.51126C3.87057 9.67695 4.00488 9.81126 4.17057 9.81126H4.44859C4.61427 9.81126 4.74859 9.67695 4.74859 9.51126V6.03525C4.74859 5.86957 4.8829 5.73525 5.04859 5.73525H5.83343C5.99912 5.73525 6.13343 5.60094 6.13343 5.43525V5.3C6.13343 5.13431 5.99912 5 5.83343 5H2.8C2.63431 5 2.5 5.13431 2.5 5.3V5.43525C2.5 5.60094 2.63431 5.73525 2.8 5.73525H3.57057C3.73625 5.73525 3.87057 5.86957 3.87057 6.03525V9.51126Z'
        fill='#707070'
      />
      <path
        d='M8.44236 8.44783C8.57288 8.44783 8.68843 8.53223 8.72814 8.65656L9.03025 9.60253C9.06996 9.72687 9.18551 9.81126 9.31603 9.81126H9.6334C9.83721 9.81126 9.9817 9.61239 9.91872 9.41856L8.55037 5.20729C8.51021 5.08369 8.39502 5 8.26506 5H7.58118C7.45077 5 7.33529 5.08425 7.29549 5.20844L5.94589 9.41971C5.88384 9.61331 6.02827 9.81126 6.23157 9.81126H6.50455C6.63666 9.81126 6.75322 9.72484 6.79161 9.59843L7.07637 8.66067C7.11476 8.53426 7.23132 8.44783 7.36343 8.44783H8.44236ZM7.69212 7.78397C7.48964 7.78397 7.34531 7.58751 7.40584 7.39429L7.65496 6.599C7.73284 6.31581 7.81072 5.98348 7.88163 5.70644C7.88267 5.70238 7.88633 5.69956 7.89053 5.69956C7.89473 5.69956 7.89839 5.70238 7.89944 5.70645C7.97035 5.98336 8.04834 6.30873 8.14038 6.599L8.39379 7.39272C8.45559 7.58626 8.31117 7.78397 8.10801 7.78397H7.69212Z'
        fill='#707070'
      />
      <path
        d='M13.6394 9.81126C13.8721 9.81126 14.0162 9.55799 13.8973 9.35802L12.7947 7.50236C12.7384 7.40759 12.7386 7.28957 12.7952 7.195L13.8376 5.45412C13.9574 5.25416 13.8133 5 13.5803 5H13.2855C13.1734 5 13.0707 5.06249 13.0191 5.16202L12.5891 5.99223C12.4408 6.28165 12.3415 6.49428 12.215 6.78889C12.2124 6.79491 12.2065 6.79887 12.2 6.79887C12.1935 6.79887 12.1876 6.79505 12.185 6.78916C12.0652 6.52197 11.9519 6.28846 11.7967 5.99223L11.3599 5.16051C11.3081 5.06182 11.2058 5 11.0943 5H10.7826C10.5514 5 10.4071 5.25043 10.523 5.45043L11.552 7.22575C11.6062 7.31927 11.6059 7.43472 11.5512 7.52795L10.477 9.35949C10.3597 9.55948 10.5039 9.81126 10.7358 9.81126H11.0331C11.1467 9.81126 11.2506 9.74706 11.3014 9.64543L11.6968 8.85472C11.8879 8.4866 12.0159 8.23081 12.1294 7.95506C12.1311 7.95089 12.1352 7.94815 12.1397 7.94815C12.1439 7.94815 12.1477 7.9505 12.1496 7.95421C12.2912 8.23029 12.4264 8.48622 12.6319 8.85472L13.061 9.65326C13.1133 9.75056 13.2148 9.81126 13.3252 9.81126H13.6394Z'
        fill='#707070'
      />
    </svg>
  );
};

export default SalesTaxReturnsIcon;
