import React, { Component } from "react";
import { isEmpty, isFinite } from "lodash";
import { Card, DataTable, Text } from "@shopify/polaris";
import { formatMoney } from "utils/numbers";
import { withTranslation } from "react-i18next";

class UnionOSSPreviewNew extends Component {
  renderSummaryRow = (sum, vat, title) => {
    const { currency } = this.props;
    const currencyIcon =
      currency.toLowerCase() === "eur" ? " \u{20AC} " : currency;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Text variant='bodyMd'>
          {title}: {formatMoney(sum)} {currencyIcon}
        </Text>
        <Text variant='bodyMd'>
          VAT due: {formatMoney(vat)} {currencyIcon}
        </Text>
      </div>
    );
  };

  renderSummary = () => {
    const {
      showDeemed,
      sumGoodsRC,
      vatGoodsRC,
      sumGoodsRCDeemed,
      vatGoodsRCDeemed,
      sumGoodsOther,
      vatGoodsOther,
      sumGoodsOtherDeemed,
      vatGoodsOtherDeemed,
      sumServices,
      vatServices,
      sumServicesDeemed,
      vatServicesDeemed,
      sumGoodsRCCorrected,
      vatGoodsRCCorrected,
      sumGoodsOtherCorrected,
      vatGoodsOtherCorrected,
      sumServicesCorrected,
      vatServicesCorrected,
      step,
    } = this.props;

    return showDeemed ? (
      <div>
        {this.renderSummaryRow(sumGoodsRC, vatGoodsRC, "Sales of goods (RC)")}
        {step > 1 &&
          this.renderSummaryRow(
            sumGoodsOther,
            vatGoodsOther,
            "Sales of goods (other)"
          )}
        {step > 2 &&
          this.renderSummaryRow(sumServices, vatServices, "Sales of services")}
        {step > 3 &&
          this.renderSummaryRow(
            sumGoodsRCDeemed,
            vatGoodsRCDeemed,
            "Sales of goods (RC deemed)"
          )}
        {step > 4 &&
          this.renderSummaryRow(
            sumGoodsOtherDeemed,
            vatGoodsOtherDeemed,
            "Sales of goods (other deemed)"
          )}
        {step > 5 &&
          this.renderSummaryRow(
            sumServicesDeemed,
            vatServicesDeemed,
            "Sales of services (deemed)"
          )}
        {step > 6 &&
          this.renderSummaryRow(
            sumGoodsRCCorrected,
            vatGoodsRCCorrected,
            "Sales of goods corrected (RC)"
          )}
        {step > 7 &&
          this.renderSummaryRow(
            sumGoodsOtherCorrected,
            vatGoodsOtherCorrected,
            "Sales of goods corrected (other)"
          )}
        {step > 8 &&
          this.renderSummaryRow(
            sumServicesCorrected,
            vatServicesCorrected,
            "Sales of services corrected"
          )}
        <br />
      </div>
    ) : (
      <div>
        {this.renderSummaryRow(sumGoodsRC, vatGoodsRC, "Sales of goods (RC)")}
        {step > 1 &&
          this.renderSummaryRow(
            sumGoodsOther,
            vatGoodsOther,
            "Sales of goods (other)"
          )}
        {step > 2 &&
          this.renderSummaryRow(sumServices, vatServices, "Sales of services")}
        {step > 3 &&
          this.renderSummaryRow(
            sumGoodsRCCorrected,
            vatGoodsRCCorrected,
            "Sales of goods corrected (RC)"
          )}
        {step > 4 &&
          this.renderSummaryRow(
            sumGoodsOtherCorrected,
            vatGoodsOtherCorrected,
            "Sales of goods corrected (other)"
          )}
        {step > 5 &&
          this.renderSummaryRow(
            sumServicesCorrected,
            vatServicesCorrected,
            "Sales of services corrected"
          )}
        <br />
      </div>
    );
  };

  renderRowByType = (data, country, title, type) => {
    const { dictOfCountries } = this.props;
    return data.map((item, ind) => {
      return [
        country,
        type === "g-other" || type === "g-other-crd"
          ? `${title} ${
              dictOfCountries
                ? dictOfCountries[item.departure_country]
                : item.departure_country
            }`
          : title,
        formatMoney(item.summ),
        `${item.vat_percent}%`,
        formatMoney(item.vat),
      ];
    });
  };

  renderSumRow = (title, revenue, vat) => {
    return [
      <Text fontWeight='semibold'>{title}</Text>,
      "",
      <Text fontWeight='semibold'>{revenue}</Text>,
      "",
      <Text fontWeight='semibold'>{vat}</Text>,
    ];
  };

  columns = [
    this.props.t("createReturns.country"),
    "",
    `Revenue (${this.props.currency})`,
    "VAT rate",
    "VAT",
  ];

  getRows = () => {
    const {
      showDeemed,
      sumGoodsRC,
      vatGoodsRC,
      sumGoodsRCDeemed,
      vatGoodsRCDeemed,
      sumGoodsOther,
      vatGoodsOther,
      sumGoodsOtherDeemed,
      vatGoodsOtherDeemed,
      sumServices,
      vatServices,
      sumServicesDeemed,
      vatServicesDeemed,
      sumGoodsRCCorrected,
      vatGoodsRCCorrected,
      sumGoodsOtherCorrected,
      vatGoodsOtherCorrected,
      sumServicesCorrected,
      vatServicesCorrected,
      data,
      currency,
      totalSumDue,
      totalVatDue,
      totalSumRefund,
      totalVatRefund,
      t,
    } = this.props;

    const rows = !isEmpty(data)
      ? data
          .map((item) => {
            const goodsRowsRC = this.renderRowByType(
              item.sales_of_goods_from_rc,
              item.country_name,
              "Sales of goods (RC)",
              "g-rc"
            );

            const goodsRowsOther = this.renderRowByType(
              item.sales_of_goods_from_other,
              item.country_name,
              "Sales of goods from",
              "g-other"
            );

            const servicesRows = this.renderRowByType(
              item.sales_of_services_oss,
              item.country_name,
              "Sales of services",
              "s"
            );

            const goodsRowsRCCorrected = this.renderRowByType(
              item.sales_of_goods_from_rc_corrected,
              item.country_name,
              "Sales of goods corrected (RC)",
              "g-rc-crd"
            );

            const goodsRowsOtherCorrected = this.renderRowByType(
              item.sales_of_goods_from_other_corrected,
              item.country_name,
              "Sales of goods corrected (other)",
              "g-other-crd"
            );

            const servicesRowsCorrected = this.renderRowByType(
              item.sales_of_services_corrected,
              item.country_name,
              "Sales of services corrected",
              "s-crd"
            );

            let goodsRowsRCDeemed = "";
            let goodsRowsOtherDeemed = "";
            let servicesRowsDeemed = "";

            if (showDeemed) {
              goodsRowsRCDeemed = this.renderRowByType(
                item.sales_of_goods_from_rc_deemed,
                item.country_name,
                "Sales of goods (RC deemed)",
                "g-rc-d"
              );

              goodsRowsOtherDeemed = this.renderRowByType(
                item.sales_of_goods_from_other_deemed,
                item.country_name,
                "Sales of goods (other deemed)",
                "g-other-d"
              );

              servicesRowsDeemed = this.renderRowByType(
                item.sales_of_services_deemed,
                item.country_name,
                "Sales of services (deemed)",
                "s-d"
              );
            }

            return [
              ...goodsRowsRC,
              ...goodsRowsRCDeemed,
              ...goodsRowsOther,
              ...goodsRowsOtherDeemed,
              ...servicesRows,
              ...servicesRowsDeemed,
              ...goodsRowsRCCorrected,
              ...goodsRowsOtherCorrected,
              ...servicesRowsCorrected,
            ];
          })
          .flat()
      : [["-", "", "0", "-", "0"]];

    if (!isFinite(totalSumDue) && !isFinite(totalSumRefund)) {
      const revenue = formatMoney(
        sumGoodsRC +
          (showDeemed ? sumGoodsRCDeemed : 0) +
          sumGoodsOther +
          (showDeemed ? sumGoodsOtherDeemed : 0) +
          sumServices +
          (showDeemed ? sumServicesDeemed : 0) +
          sumGoodsRCCorrected +
          sumGoodsOtherCorrected +
          sumServicesCorrected
      );
      
      const vat = formatMoney(
        vatGoodsRC +
          (showDeemed ? vatGoodsRCDeemed : 0) +
          vatGoodsOther +
          (showDeemed ? vatGoodsOtherDeemed : 0) +
          vatServices +
          (showDeemed ? vatServicesDeemed : 0) +
          vatGoodsRCCorrected +
          vatGoodsOtherCorrected +
          vatServicesCorrected
      );

      rows.push(this.renderSumRow("Total", revenue, vat));
    }

    if (isFinite(totalSumDue)) {
      rows.push(
        this.renderSumRow(
          "Total due",
          formatMoney(totalSumDue),
          formatMoney(totalVatDue)
        )
      );
    }

    if (isFinite(totalSumRefund)) {
      rows.push(
        this.renderSumRow(
          "Total refund",
          formatMoney(totalSumRefund),
          formatMoney(totalVatRefund)
        )
      );
    }

    return rows;
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        {this.renderSummary()}
        {data && (
          <Card>
            <DataTable
              columnContentTypes={["text", "text", "text", "numeric"]}
              headings={this.columns.map((label) => (
                <Text fontWeight='semibold'>{label}</Text>
              ))}
              rows={this.getRows()}
              hideScrollIndicator
              increasedTableDensity
            />
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(UnionOSSPreviewNew);
