import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";

import {
  Button,
  ButtonGroup,
  Card,
  Text,
  DataTable,
  TextField,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow, { formatRow2 } from 'utils/formatRow'
import { setQuantity } from '../../../../redux/features/declarations/declarationsSlice'

const Quantity = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprSubcategories: { selectedCategory5, selectedCategory1, selectedCategory2, selectedCategory3, selectedCategory4, quantityArr, unitsDefault }
  } = useSelector((state) => state.declarations);
  
  const getQuantityValue = (quantityArr, category, subCategory, subSubCategory, subSubSubCategory) => {
    
    const foundItem = quantityArr.find((item) => {
      
      return (
        item.category === category &&
        item.subCategory === subCategory &&
        item.subSubSubCategory === subSubSubCategory &&
        (subSubCategory?.name
          ? subSubCategory.name === item.subSubCategory?.name && subSubCategory.units === item.subSubCategory?.units
          : subSubCategory === item.subSubCategory)
      );
    });
    return foundItem?.quantity || '';
  };


  
  const handleChange = (
    quantity,
    category,
    subCategory,
    subSubCategory,
    subSubSubCategory,
    recyclateOfPrimary
  ) => {
    
    dispatch(
      setQuantity({
        quantity,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory,
        recyclateOfPrimary
      })
    );
  };
  
  const findLongestArray = (...arrays) => {
    return arrays.reduce((longest, current) => {
      
      const currentCount = current.reduce((count, item) => {
        if (Array.isArray(item)) {
          return count + item.length;
        } else {
          return count + 1;
        }
      }, 0);
      
      const longestCount = longest.reduce((count, item) => {
        if (Array.isArray(item)) {
          return count + item.length;
        } else {
          return count + 1;
        }
      }, 0);
      
      return currentCount > longestCount ? current : longest;
    }, []);
  };
  
  const longestSelectedCategory = findLongestArray(
    selectedCategory1,
    selectedCategory2,
    selectedCategory3,
    selectedCategory4,
    selectedCategory5
  );
  
  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
              {rowData[1]}
            </div>;
          },
        ],
      },
    },
    ...(selectedCategory3.length > 0
      ? [
          {
            header: {
              label: "Subcategory 3",
            },
            cell: {
              formatters: [
                (_,
                  {rowData}) => {
                  return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{rowData[2]?.name === undefined ? rowData[2] : `${rowData[2]?.name}`}</div>
                },
              ],
            },
          },
        ]
        :
        []
    ),
    ...(selectedCategory4.length > 0
      ? [
        {
          header: {
            label: "Subcategory 4",
          },
          cell: {
            formatters: [
              (_, { rowData }) => {
                return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}> {rowData[3]?.name === undefined ? rowData[3] : `${rowData[3]?.name} ${rowData[3]?.units}`} </div>
              },
            ],
          },
        },
      ]
        :
        []
      ),
    ...(selectedCategory5.length > 0
        ? [
          {
            header: {
              label: "Subcategory 5",
            },
            cell: {
              formatters: [
                (_, { rowData }) => {
                  return rowData[4];
                },
              ],
            },
          },
        ]
        :
        []
    ),
    
    {
      header: {
        label: "Quantity",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            if (!Array.isArray(rowData)) return null
            const [
              category,
              subCategory,
              subSubCategory,
              subSubSubCategory,
            ] = Array.isArray(rowData) ? rowData : [rowData.category, rowData.subCategory, rowData.subSubCategory, rowData.subSubSubCategory, rowData.quantity];
            const recyclateOfPrimary= rowData?.[4] ? rowData?.[4] : undefined;
            return (
              <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
              <TextField
                value={
                  getQuantityValue(quantityArr, category, subCategory, subSubCategory, subSubSubCategory) || rowData?.quantity || rowData?.[5] || ''
                }
                placeholder="Quantity"
                autoComplete="off"
                style={{ minWidth: '100px' }}
                onChange={(newQuantity) => {
                  handleChange(
                    newQuantity,
                    category,
                    subCategory,
                    subSubCategory,
                    subSubSubCategory,
                    recyclateOfPrimary
                  );
                }}
              />
                {(rowData[2]?.units || subSubSubCategory?.units )? (
                  <span style={{ marginLeft: '8px' }}>
                    {rowData[2]?.units || subSubSubCategory?.units}
                  </span>
                )
                  :
                  (<span style={{ marginLeft: '8px' }}>
                    {unitsDefault}
                  </span>
                )}
            </div>
            );
          },
        ],
      },
    },
  ];
  const arr = selectedCategory5.length !== 0 ? selectedCategory5 : longestSelectedCategory
  const rows = selectedCategory5.length !== 0 ? formatRow(selectedCategory5, columns) : formatRow2(arr, columns);

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
          <Button primary onClick={toNextStep}>
            {t("orderReg.next")}
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default Quantity;
