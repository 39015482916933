import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  createDeclaration,
  fetchTypesOfReport, setVatReturnsData,
} from "../../redux/features/declarations/declarationsSlice";
import {
  fetchCountries,
  fetchCountriesEU,
} from "../../redux/features/other/otherSlice";
import { fetchRegNumbers } from "../../redux/features/registration/registrationSlice";
import _ from "lodash";
import {
  Page,
  Layout,
  Button,
  Card,
  TextStyle,
  Banner,
  Select,
  FormLayout,
  ButtonGroup,
  Text, Typography,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import Modal from "components/Modal";
import { Link } from "react-router-dom";

import GoodsReturn from "./Returns/GoodsReturn";
import UnionOSSReturn from "./Returns/UnionOSSReturn";
import IOSSReturn from "./Returns/IOSSReturn";
import OSSReturn from "./Returns/OSSReturn";
import VatReturnROW from "./Returns/VatReturnROW";

import parrotIMG from "img/parrot.svg";
import PageHelmet from "components/PageHelmet";
import { buildPeriods } from "utils/periods";
import { getYears } from "utils/years";
import { createCustomDispatch } from "helpers/customDispatch";
import { getCountryName, getProvinceName } from "./utils";

const years = getYears({ yearsBefore: 5 });

class CreateReturn extends Component {
  constructor(props) {
    super(props);

    const {
      id = "",
      countryId = "",
      countryName = "",
      period = "",
      periodType = "",
      year = "",
      reason = "",
      typeOfReport = "",
    } = this.props?.params || {};

    this.state = {
      country: id ? countryId : "",
      countryName: id ? countryName : "",
      period: id
        ? `${period}${periodType === "quarter" ? "q" : ""}${
            periodType === "year" ? "y" : ""
          }`
        : "",
      regReason: id ? +reason : "",
      year: id ? year : "",
      typeReport: id ? +typeOfReport : 1,
      periodType: id ? periodType : "",
      isDigital: false,
      currency: "",

      creationStatus: "",

      actionDialogs: {
        subscription: { open: false },
      },
    };
  }

  componentDidMount() {
    if (this.props?.params?.id) {
      //   this.fillReturn();
    }
    this.props.fetchCountries();
    this.props.fetchCountriesEU();
    this.props.fetchRegNumbers(true);
    this.props.fetchTypesOfReport();
  }

  getPeriodName = (type, value) => {
    let name = "";
    const periods = buildPeriods({ year: true, quarters: true, month: true });

    periods.forEach((period) => {
      if (
        period.type === type &&
        period.value.toString() === value.toString()
      ) {
        name = period.label;
      }
    });
    return name;
  };

  getTitleString = () => {
    const {
      regReason,
      typeReport,
      countryName = "",
      customerState,
    } = this.state;
    const { t } = this.props;

    const period = `${this.getPeriodName(
      this.state.periodType,
      this.state.period
    )} ${this.state.year}`;

    if (typeReport === 2) {
      return `Sales List for ${period} for ${countryName}`;
    }

    if (customerState) {
      return `PST return for ${countryName} / ${customerState} for ${period}`;
    }

    return `${regReason === 1 ? t("createReturns.vatReturn") : ""}
    ${regReason === 3 ? `Cross-border digital services ` : ""}
    ${regReason === 4 ? t("createReturns.salesFromPrem") : ""}
    ${regReason === 5 ? `non-union OSS VAT Return ` : ""}
    ${regReason === 6 ? `IOSS VAT Return ` : ""}
    ${regReason === 7 ? `Union OSS VAT Return ` : ""}
    ${regReason === 8 ? `VAT return ROW ` : ""}
    for ${period} for ${countryName}`;
  };

  doDiscard = () =>
    this.setState({
      country: "",
      countryName: "",
      regReason: "",
      year: "",
      period: "",
      periodType: "",
    });

  fillReturn = () => {
    const { id } = this.props.params;
    this.setState({
      addingError: null,
    });

    const params = {
      customer_country_id: this.state.countryValue,
      customer_state: this.state.customerState,
      period: Number.parseInt(this.state.period, 10),
      period_type: this.state.periodType,
      period_year: +this.state.year,
      reason_for_registration_id: this.state.regReason,
      type_of_report: this.state.typeReport,
      currency: this.state.currency,
    };

    if (id) {
      params.draft_id = +id;
      params.load_draft = true;
    }
    
    this.props.setVatReturnsData(params)
    
    this.props
      .createDeclaration(params)
      .then((result) => {
        if (result && !result.message) {
          this.setState({
            addingResult: result,
            creationStatus: "result",
          });
        } else {
          this.setState({
            addingResultMessage: result.message,
          });
          this.handleActionDialogsOpen("subscription");
        }
      })
      .catch((result) => {
        this.setState({
          addingError: result,
        });
      });
  };

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  }

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  editSalesList = ({ id, sum }) => {
    this.setState({
      addingResult: {
        ...this.state.addingResult,
        sales_list: this.state.addingResult.sales_list.map((el) => {
          if (el.id === id) {
            return {
              ...el,
              sum,
            };
          }
          return el;
        }),
      },
    });
  };

  resetAddingResult = () => {
    this.setState({
      addingResult: null,
      creationStatus: null,
    });
  };

  getCurrentCountryCurrency() {
    const { countryName, customerState } = this.state;
    const { regNumbers } = this.props;
    const countryInCountriesList = regNumbers.find(
      ({ country, state }) =>
        country === countryName && (state ? customerState === state : true)
    );
    const countryCurrency = countryInCountriesList?.currency;
    // debugger;
    if (
      countryCurrency === "EUR" ||
      countryCurrency === "USD" ||
      !countryCurrency
    ) {
      return null;
    }

    return { label: countryCurrency, value: countryCurrency };
  }

  getDefaultCurrencies() {
    return [
      { label: "EUR", value: "EUR" },
      { label: "USD", value: "USD" },
    ];
  }

  getPeriodsByReason() {
    const { typeReport, regReason } = this.state;

    let periods = [];

    switch (regReason) {
      case 5:
      case 7:
      case 8:
        periods = buildPeriods({ quarters: true });
        break;

      case 6:
        periods = buildPeriods({ months: true });
        break;

      default:
        periods = buildPeriods({ year: true, quarters: true, months: true });

        if (typeReport === 2) {
          periods.splice(1, 1);
        }

        if (regReason === 1 || regReason === 4) {
          periods.push(
            ...buildPeriods({ biAnnual: true, removeDefault: true })
          );
        }
    }

    return periods;
  }

  step1() {
    const { regNumbers, typesOfReport, t } = this.props;
    const { id } = this.props.params;
    const regCountries = [];

    const currentCountryCurrency = this.getCurrentCountryCurrency();

    const currenciesList = [
      { label: "", value: "" },
      ...this.getDefaultCurrencies(),
    ];

    if (currentCountryCurrency) {
      currenciesList.push(currentCountryCurrency);
    }

    if (regNumbers) {
      regNumbers.forEach((regNumber) => {
        regCountries.push({
          id: regNumber.country_id,
          name: regNumber.country,
          state: regNumber.state,
        });
      });
    }
    let optionsCountries = [
      {
        key: "cntr",
        label: "",
        value: "",
      },
    ];
    _.map(regCountries, (country, index) => {
      let label, value, state;

      if (this.state.typeReport === 1) {
        label = `${country.name} ${country.state ? `(${country.state})` : ""}`;
        value = `${country.id} ${
          country.state ? `(${country.state})` : ""
        }`.trim();
        state = country.state;
      } else {
        label = country.name;
        value = country.id;
      }

      optionsCountries.push({
        state,
        label,
        value,
        key: country.id + index,
      });
    });

    optionsCountries = _.uniqWith(optionsCountries, (a, b) => {
      return a.value === b.value && (!a.state || a.state === b.state);
    });
  
    let reasonsItems = [
      {
        key: "rsns",
        label: "",
        value: "",
      },
    ];

    if (this.state.countryValue) {
      regNumbers.forEach((regNumber, index) => {
        if (regNumber.country_id === this.state.countryValue) {
          let label = "";
          switch (regNumber.reason_for_registration) {
            case "Sales from premises":
              label = t("createReturns.salesFromPrem");
              break;
            case "MOSS":
              label = t("createReturns.moss");
              break;
            case "Distant sales":
              label = t("createReturns.distSales");
              break;
            case "VAT return ROW":
              label = "VAT return ROW";
              break;
            default:
              label = regNumber.reason_for_registration;
          }

          reasonsItems.push({
            key: regNumber.id + index,
            label,
            value: `${regNumber.reason_for_registration_id}`,
          });
        }
      });
      reasonsItems = _.uniqBy(reasonsItems, "value");
    }

    const periods = this.getPeriodsByReason();

    return (
      <div>
        {this.state.creationStatus !== "result" && (
          <Layout>
            <Layout.AnnotatedSection
              title={t("createReturns.fillReturnParams")}
              description={
                <Text variant="bodyMd" color="subdued">
                  {t("createReturns.addVatNumber")}
                </Text>
              }
            >
              <Card sectioned>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      id='type'
                      name='type'
                      disabled={!!id}
                      label={t("createReturns.typeOfRep")}
                      options={typesOfReport.map((type) => ({
                        value: type.id.toString(),
                        label: type.type_of_report,
                      }))}
                      onChange={(value) => {
                        this.setState({ typeReport: +value });
                        this.doDiscard();
                      }}
                      value={this.state.typeReport.toString()}
                    />
                    <Select
                      id='reason'
                      name='reason'
                      disabled={!this.state.country || !!id}
                      label={t("createReturns.reasonReg")}
                      options={reasonsItems}
                      onChange={(value) => this.setState({ regReason: +value })}
                      value={this.state.regReason.toString()}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      id='formCountry'
                      name='from_country'
                      disabled={!!id}
                      label={t("createReturns.country")}
                      options={optionsCountries}
                      onChange={(country) => {
                        const value = getCountryName(country);
                        const customerState = getProvinceName(country);
                        let countryName = "";
                        let isDigital;
                        regNumbers.forEach((element) => {
                          if (
                            element.country_id === value &&
                            element.state === customerState
                          ) {
                            countryName = element.country;
                            isDigital = element.is_digital;
                            return;
                          }
                        });

                        this.setState({
                          customerState,
                          isDigital,
                          countryName,
                          country,
                          countryValue: value,
                          regReason: "",
                          creationStatus: undefined,
                        });
                      }}
                      value={this.state.country}
                    />
                    <Select
                      id='period'
                      name='period'
                      label={t("createReturns.period")}
                      disabled={!this.state.regReason || !!id}
                      options={periods}
                      onChange={(value, i) => {
                        let periodType = "";
                        if (value.includes("q")) {
                          periodType = "quarter";
                        } else if (value.includes("y")) {
                          periodType = "year";
                        } else if (value.includes("b")) {
                          periodType = "bi-annual";
                        } else {
                          periodType = "month";
                        }
                        this.setState({
                          period: value,
                          periodType: periodType,
                          paymentStatus: null,
                        });
                      }}
                      value={this.state.period.toString()}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      id='year'
                      name='year'
                      disabled={!!id}
                      label={t("createReturns.year")}
                      options={years}
                      onChange={(value) =>
                        this.setState({
                          year: value,
                          creationStatus: undefined,
                        })
                      }
                      value={this.state.year}
                    />
                    {this.state.typeReport === 1 &&
                    (this.state.regReason === 3 ||
                      this.state.regReason === 8) ? (
                      <Select
                        id='currency'
                        name='currency'
                        label={t("createReturns.currency")}
                        disabled={!this.state.country}
                        options={currenciesList}
                        onChange={(value) => {
                          this.setState(() => ({
                            currency: value,
                          }));
                        }}
                        value={this.state.currency}
                      />
                    ) : (
                      <br />
                    )}
                  </FormLayout.Group>
                </FormLayout>
                <br />
                <SaveBar>
                  <ButtonGroup>
                    <Button
                      disabled={
                        (!this.state.country &&
                          !this.state.regReason &&
                          !this.state.year &&
                          !this.state.period &&
                          !(
                            this.state.typeReport === 1 &&
                            (this.state.regReason === 3 ||
                              this.state.regReason === 8) &&
                            this.state.currency
                          )) ||
                        id
                          ? true
                          : false
                      }
                      onClick={this.doDiscard}
                    >
                      {t("createReturns.discard")}
                    </Button>
                    <Button
                      primary
                      disabled={
                        !(
                          this.state.country &&
                          this.state.period &&
                          this.state.year &&
                          this.state.regReason &&
                          (this.state.typeReport !== 1 ||
                            (this.state.regReason !== 3 &&
                              this.state.regReason !== 8) ||
                            this.state.currency)
                        ) && !id
                      }
                      onClick={() => {
                        if (
                          this.state.regReason !== 1 ||
                          this.state.regReason !== 4
                        ) {
                          this.fillReturn();
                          this.setState({
                            creationStatus: "success",
                          });
                        } else {
                          //this.handleNext();
                          this.fillReturn();
                        }
                      }}
                      loading={this.state.creationStatus}
                    >
                      {t("createReturns.next")}
                    </Button>
                  </ButtonGroup>
                </SaveBar>
              </Card>
            </Layout.AnnotatedSection>
          </Layout>
        )}
      </div>
    );
  }

  renderSubscriptionDialog = () => {
    const { t } = this.props;
    const { actionDialogs, addingResultMessage } = this.state;

    return (
      <Modal
        iconType={"warning"}
        title={addingResultMessage || ""}
        contentOnCenter
        titleOnCenter
        visible={actionDialogs.subscription.open}
        onClose={() => this.handleActionDialogsClose("subscription")}
        content={
          <div
            style={{ display: "flex", alignItems: "center", maxWidth: "450px" }}
          >
            <p>
              {t("createReturns.contUsing")} (
              {
                <Link className='Polaris-Link' to='/subscription'>
                  {t("createReturns.selectPlan")}
                </Link>
              }
              )
            </p>
            <img src={parrotIMG} alt='lovat_logo' width='20%' />
          </div>
        }
      />
    );
  };

  renderErrorBanner = () => {
    const { t } = this.props;
    const { addingError, creationStatus } = this.state;

    return (
      creationStatus &&
      addingError && (
        <Layout>
          <Layout.AnnotatedSection>
            <br />
            <Banner status='critical'>
              <p>{`${t("createReturns.err")} ${addingError.error || ""}`}</p>
            </Banner>
          </Layout.AnnotatedSection>
        </Layout>
      )
    );
  };

  getReturnParams() {
    return {
      customer_country_id: this.state.countryValue,
      is_digital: this.state.isDigital,
      customer_state: this.state.customerState,
      period: Number.parseInt(this.state.period, 10),
      period_type: this.state.periodType,
      period_year: +this.state.year,
      reason_for_registration_id: this.state.regReason,
      type_of_report: this.state.typeReport,
      currency: this.state.currency,
    };
  }

  createReturnWithProps = (Component, customProps = {}) => {
    const commonProps = {
      title: this.getTitleString(),
      history: this.props.history,
      returnData: this.state.addingResult,
      reset: this.resetAddingResult,
      countryName: this.state.countryName,
      returnParams: this.getReturnParams(),
    };

    const props = {
      ...commonProps,
      ...customProps,
    };

    return <Component {...props} />;
  };

  renderGoodsReturn = () => {
    const props = {
      deleteSalesListItem: this.fillReturn,
      editSalesList: this.editSalesList,
    };

    return this.createReturnWithProps(GoodsReturn, props);
  };

  renderVATReturnROW = () => {
    const props = {
      deleteSalesListItem: this.fillReturn,
    };
    return this.createReturnWithProps(VatReturnROW, props);
  };

  renderIOSSReturn = () => this.createReturnWithProps(IOSSReturn);

  renderOSSReturn = () => this.createReturnWithProps(OSSReturn);

  renderUnionOSSReturn = () => this.createReturnWithProps(UnionOSSReturn);

  renderReturn = () => {
    const { addingResult, regReason } = this.state;

    if (!addingResult) {
      return null;
    }

    const goodsReturnIndexes = [1, 3, 4];

    const renderFunctions = {
      ...Object.fromEntries(
        goodsReturnIndexes.map((value) => [value, this.renderGoodsReturn])
      ),
      5: this.renderOSSReturn,
      6: this.renderIOSSReturn,
      7: this.renderUnionOSSReturn,
      8: this.renderVATReturnROW,
    };

    const renderFunction = renderFunctions[regReason];

    return renderFunction ? renderFunction() : null;
  };

  getPageTitle = () => {
    const { t } = this.props;
    const { typeReport } = this.state;
    // console.log('typeReport', typeReport)
    let titleString;

    switch (typeReport) {
      case 1:
        titleString = t("createReturns.createVR");
        break;
      case 2:
        titleString = t("createReturns.createSalesList");
        break;
      default:
        titleString = "";
    }

    return (
      <Text variant='heading3xl' as='span'>
        {titleString}
      </Text>
    );
  };

  render() {
    const title = this.getPageTitle();

    return (
      <Page fullWidth title={title}>
        <PageHelmet title={"Create VAT Return"} />

        {this.step1()}

        {this.renderReturn()}

        {this.renderErrorBanner()}

        {this.renderSubscriptionDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.other.countries,
  countriesEU: state.other.countriesEU,
  regNumbers: state.vatRegistration.regNumbers,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
  salesListFields: state.transactions.salesListFields,
  typesOfReport: state.declarations.typesOfReport,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclaration: (params) => dispatch(createDeclaration(params)),
    fetchRegNumbers: (params) => dispatch(fetchRegNumbers(params)),
    setVatReturnsData: (params) => dispatch(setVatReturnsData(params)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCountriesEU: () => dispatch(fetchCountriesEU()),
    fetchTypesOfReport: () => dispatch(fetchTypesOfReport()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateReturn))
);
