import React from "react";
import _ from "lodash";
import { preciseRound } from "utils/numbers";
import withRouter from "helpers/withRouter";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import formatRow from "utils/formatRow";
import { Card, DataTable, Text } from "@shopify/polaris";

class ReturnPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { preview, currency, t } = this.props;

    const columns = [
      {
        property: "name",
        header: {
          label: t("createReturns.vatReturnDetails"),
        },
      },
      {
        property: "amount",
        header: {
          label: `${t("createReturns.amount")} (${currency})`,
        },
        cell: {
          formatters: [(value) => preciseRound(value, 2)],
        },
      },
    ];

    const sortedRows = formatRow(preview, columns);
    
    return (
      <Card>
        <DataTable
          columnContentTypes={["text", "numeric"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={sortedRows.slice(0, -1)} // without total
          hideScrollIndicator
          increasedTableDensity
          showTotalsInFooter
          totals={sortedRows.at(-1)} // showing total here
          totalsName={{
            singular: sortedRows.at(-1)[0],
            plural: sortedRows.at(-1)[0],
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ReturnPreview))
);
