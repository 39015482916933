import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Card, DataTable, Text, TextStyle } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { formatMoney } from "utils/numbers";

class UnionOSSPreview extends Component {
  renderSummaryRow = (sum, vat, title) => {
    const { currency } = this.props;
    const currencyIcon =
      currency.toLowerCase() === "eur" ? " \u{20AC} " : currency;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Text variant='bodyMd'>
          {title}: {formatMoney(sum)} {currencyIcon}
        </Text>
        <Text variant='bodyMd'>
          VAT due: {formatMoney(vat)} {currencyIcon}
        </Text>
      </div>
    );
  };

  renderSummary = () => {
    const {
      showDeemed,
      sumGoodsRC,
      vatGoodsRC,
      sumGoodsRCDeemed,
      vatGoodsRCDeemed,
      sumGoodsOther,
      vatGoodsOther,
      sumGoodsOtherDeemed,
      vatGoodsOtherDeemed,
      sumServices,
      vatServices,
      sumServicesDeemed,
      vatServicesDeemed,
      sumGoodsRCCorrected,
      vatGoodsRCCorrected,
      sumGoodsOtherCorrected,
      vatGoodsOtherCorrected,
      sumServicesCorrected,
      vatServicesCorrected,
      step,
    } = this.props;

    return showDeemed ? (
      <div>
        {this.renderSummaryRow(sumGoodsRC, vatGoodsRC, "Sales of goods (RC)")}
        {step > 1 &&
          this.renderSummaryRow(
            sumGoodsRCDeemed,
            vatGoodsRCDeemed,
            "Sales of goods (RC deemed)"
          )}
        {step > 2 &&
          this.renderSummaryRow(
            sumGoodsOther,
            vatGoodsOther,
            "Sales of goods (other)"
          )}
        {step > 3 &&
          this.renderSummaryRow(
            sumGoodsOtherDeemed,
            vatGoodsOtherDeemed,
            "Sales of goods (other deemed)"
          )}
        {step > 4 &&
          this.renderSummaryRow(sumServices, vatServices, "Sales of services")}
        {step > 5 &&
          this.renderSummaryRow(
            sumServicesDeemed,
            vatServicesDeemed,
            "Sales of services (deemed)"
          )}
        {step > 6 &&
          this.renderSummaryRow(
            sumGoodsRCCorrected,
            vatGoodsRCCorrected,
            "Sales of goods corrected (RC)"
          )}
        {step > 6 &&
          this.renderSummaryRow(
            sumGoodsOtherCorrected,
            vatGoodsOtherCorrected,
            "Sales of goods corrected (other)"
          )}
        {step > 6 &&
          this.renderSummaryRow(
            sumServicesCorrected,
            vatServicesCorrected,
            "Sales of services corrected"
          )}
        <br />
      </div>
    ) : (
      <div>
        {this.renderSummaryRow(sumGoodsRC, vatGoodsRC, "Sales of goods (RC)")}
        {step > 1 &&
          this.renderSummaryRow(
            sumGoodsOther,
            vatGoodsOther,
            "Sales of goods (other)"
          )}
        {step > 2 &&
          this.renderSummaryRow(sumServices, vatServices, "Sales of services")}
        {step > 3 &&
          this.renderSummaryRow(
            sumGoodsRCCorrected,
            vatGoodsRCCorrected,
            "Sales of goods corrected (RC)"
          )}
        {step > 3 &&
          this.renderSummaryRow(
            sumGoodsOtherCorrected,
            vatGoodsOtherCorrected,
            "Sales of goods corrected (other)"
          )}
        {step > 3 &&
          this.renderSummaryRow(
            sumServicesCorrected,
            vatServicesCorrected,
            "Sales of services corrected"
          )}
        <br />
      </div>
    );
  };

  renderRowByType = (data, country, title, type) =>
    data.map((item, ind) => {
      return [
        ind === 0 ? country : "",
        ind === 0 ? title : "",
        formatMoney(item.total_sum),
        `${item.vat_percent}%`,
        formatMoney(item.total_vat),
      ];
    });

  renderSumRow = (title, revenue, vat) => {
    return [
      <Text fontWeight='semibold'>{title}</Text>,
      "",
      <Text fontWeight='semibold'>{revenue}</Text>,
      "",
      <Text fontWeight='semibold'>{vat}</Text>,
    ];
  };

  columns = [
    this.props.t("createReturns.country"),
    "",
    `Revenue (${this.props.currency})`,
    "VAT rate",
    "VAT",
  ];

  getRows = () => {
    const {
      showDeemed,
      sumGoodsRC,
      vatGoodsRC,
      sumGoodsRCDeemed,
      vatGoodsRCDeemed,
      sumGoodsOther,
      vatGoodsOther,
      sumGoodsOtherDeemed,
      vatGoodsOtherDeemed,
      sumServices,
      vatServices,
      sumServicesDeemed,
      vatServicesDeemed,
      sumGoodsRCCorrected,
      vatGoodsRCCorrected,
      sumGoodsOtherCorrected,
      vatGoodsOtherCorrected,
      sumServicesCorrected,
      vatServicesCorrected,
      data,
      currency,
      totalSumDue,
      totalVatDue,
      totalSumRefund,
      totalVatRefund,
      t,
    } = this.props;

    const rows = !isEmpty(data)
      ? data
          .map((item) => {
            const isEmptyGoodsRC = isEmpty(item.sales_of_goods_from_rc);
            const isEmptyGoodsRCDeemed = isEmpty(
              item.sales_of_goods_from_rc_deemed
            );
            const isEmptyGoodsOther = isEmpty(item.sales_of_goods_from_other);
            const isEmptyGoodsOtherDeemed = isEmpty(
              item.sales_of_goods_from_other_deemed
            );
            const isEmptyServices = isEmpty(item.sales_of_services);
            const isEmptyServicesDeemed = isEmpty(
              item.sales_of_services_deemed
            );
            const isEmptyGoodsRCCorr = isEmpty(
              item.sales_of_goods_from_rc_corrected
            );
            const isEmptyGoodsOtherCorr = isEmpty(
              item.sales_of_goods_from_other_corrected
            );

            const goodsRowsRC = this.renderRowByType(
              item.sales_of_goods_from_rc,
              item.country,
              "Sales of goods (RC)",
              "g-rc"
            );

            const goodsRowsOther = this.renderRowByType(
              item.sales_of_goods_from_other,
              (showDeemed && isEmptyGoodsRC && isEmptyGoodsRCDeemed) ||
                (!showDeemed && isEmptyGoodsRC)
                ? item.country
                : "",
              "Sales of goods (other)",
              "g-other"
            );

            const servicesRows = this.renderRowByType(
              item.sales_of_services,
              (showDeemed &&
                isEmptyGoodsRC &&
                isEmptyGoodsRCDeemed &&
                isEmptyGoodsOther &&
                isEmptyGoodsOtherDeemed) ||
                (!showDeemed && isEmptyGoodsRC && isEmptyGoodsOther)
                ? item.country
                : "",
              "Sales of services",
              "s"
            );

            const goodsRowsRCCorrected = this.renderRowByType(
              item.sales_of_goods_from_rc_corrected,
              (showDeemed &&
                isEmptyGoodsRC &&
                isEmptyGoodsRCDeemed &&
                isEmptyGoodsOther &&
                isEmptyGoodsOtherDeemed &&
                isEmptyServices &&
                isEmptyServicesDeemed) ||
                (!showDeemed &&
                  isEmptyGoodsRC &&
                  isEmptyGoodsOther &&
                  isEmptyServices)
                ? item.country
                : "",
              "Sales of goods corrected (RC)",
              "g-rc-crd"
            );

            const goodsRowsOtherCorrected = this.renderRowByType(
              item.sales_of_goods_from_other_corrected,
              (showDeemed &&
                isEmptyGoodsRC &&
                isEmptyGoodsRCDeemed &&
                isEmptyGoodsOther &&
                isEmptyGoodsOtherDeemed &&
                isEmptyServices &&
                isEmptyServicesDeemed &&
                isEmptyGoodsRCCorr) ||
                (!showDeemed &&
                  isEmptyGoodsRC &&
                  isEmptyGoodsOther &&
                  isEmptyServices &&
                  isEmptyGoodsRCCorr)
                ? item.country
                : "",
              "Sales of goods corrected (other)",
              "g-other-crd"
            );

            const servicesRowsCorrected = this.renderRowByType(
              item.sales_of_services_corrected,
              (showDeemed &&
                isEmptyGoodsRC &&
                isEmptyGoodsRCDeemed &&
                isEmptyGoodsOther &&
                isEmptyGoodsOtherDeemed &&
                isEmptyServices &&
                isEmptyServicesDeemed &&
                isEmptyGoodsRCCorr &&
                isEmptyGoodsOtherCorr) ||
                (!showDeemed &&
                  isEmptyGoodsRC &&
                  isEmptyGoodsOther &&
                  isEmptyServices &&
                  isEmptyGoodsRCCorr &&
                  isEmptyGoodsOtherCorr)
                ? item.country
                : "",
              "Sales of services corrected",
              "s-crd"
            );

            let goodsRowsRCDeemed = "";
            let goodsRowsOtherDeemed = "";
            let servicesRowsDeemed = "";

            if (showDeemed) {
              goodsRowsRCDeemed = this.renderRowByType(
                item.sales_of_goods_from_rc_deemed,
                isEmptyGoodsRC ? item.country : "",
                "Sales of goods (RC deemed)",
                "g-rc-d"
              );

              goodsRowsOtherDeemed = this.renderRowByType(
                item.sales_of_goods_from_other_deemed,
                isEmptyGoodsRC && isEmptyGoodsRCDeemed && isEmptyGoodsOther
                  ? item.country
                  : "",
                "Sales of goods (other deemed)",
                "g-other-d"
              );

              servicesRowsDeemed = this.renderRowByType(
                item.sales_of_services_deemed,
                (showDeemed &&
                  isEmptyGoodsRC &&
                  isEmptyGoodsRCDeemed &&
                  isEmptyGoodsOther &&
                  isEmptyGoodsOtherDeemed &&
                  isEmptyServices) ||
                  (isEmptyGoodsRC && isEmptyGoodsOther)
                  ? item.country
                  : "",
                "Sales of services (deemed)",
                "s-d"
              );
            }

            return [
              ...goodsRowsRC,
              ...goodsRowsRCDeemed,
              ...goodsRowsOther,
              ...goodsRowsOtherDeemed,
              ...servicesRows,
              ...servicesRowsDeemed,
              ...goodsRowsRCCorrected,
              ...goodsRowsOtherCorrected,
              ...servicesRowsCorrected,
            ];
          })
          .flat()
      : [["-", "", "0", "-", "0"]];

    const revenue = formatMoney(
      sumGoodsRC +
        (showDeemed ? sumGoodsRCDeemed : 0) +
        sumGoodsOther +
        (showDeemed ? sumGoodsOtherDeemed : 0) +
        sumServices +
        (showDeemed ? sumServicesDeemed : 0) +
        sumGoodsRCCorrected +
        sumGoodsOtherCorrected +
        sumServicesCorrected
    );
    const vat = formatMoney(
      vatGoodsRC +
        (showDeemed ? vatGoodsRCDeemed : 0) +
        vatGoodsOther +
        (showDeemed ? vatGoodsOtherDeemed : 0) +
        vatServices +
        (showDeemed ? vatServicesDeemed : 0) +
        vatGoodsRCCorrected +
        vatGoodsOtherCorrected +
        vatServicesCorrected
    );
    rows.push(this.renderSumRow("Total", revenue, vat));
    
    return rows;
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        {this.renderSummary()}
        {data && (
          <Card>
            <DataTable
              columnContentTypes={["text", "text", "text", "numeric"]}
              headings={this.columns.map((label) => (
                <Text fontWeight='semibold'>{label}</Text>
              ))}
              rows={this.getRows()}
              hideScrollIndicator
              increasedTableDensity
            />
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(UnionOSSPreview);
