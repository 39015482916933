import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import * as XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";
import {
  Card,
  Layout,
  Button,
  ButtonGroup,
  ChoiceList,
  TextField,
  FormLayout,
  Select,
  Badge,
  ResourceList,
  Banner,
  Scrollable,
  Spinner,
  DataTable,
  Text,
  Tooltip,
  Checkbox, Box,
} from "@shopify/polaris";
import HighlightableDataTable from "components/HighlightableDataTable";
import { parseServerError } from "utils/errors";
import { preciseRound } from "utils/numbers";
import SaveBar from "components/SaveBar/SaveBar";
import { formatDateForServer } from "utils/dates";
import { Link } from "react-router-dom";
import Modal from "components/Modal";
import Stepper from "components/Stepper";
import Datepicker from "components/Datepicker/Datepicker";
import InfoTooltip from "components/InfoTooltip";

import parrotIMG from "img/parrot.svg";
import step1IMG from "img/step1.svg";
import formatRow from "utils/formatRow";
import {
  checkVatNumbers,
  createDeclaration,
  editTransaction
} from '../../../redux/features/declarations/declarationsSlice'
import { fetchCountriesEU } from '../../../redux/features/other/otherSlice'
import {
  addSalesList,
  deleteSalesListById,
  fetchSalesListFields
} from '../../../redux/features/transactions/transactionsSlice'
import ReturnPreview from "../Previews/ReturnPreview";
import SalesListPreview from "../Previews/SalesListPreview";

import deleteIMG from "img/delete.svg";
import editIMG from "img/settings.svg";
import { AlertIcon } from "icons";
import { createCustomDispatch } from "helpers/customDispatch";
import Step2NewFunctional from './step2NewFunctional/Step2NewFunctional'

let importData = [];
let importFileName = "";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class GoodsReturn extends Component {
  constructor(props) {
    super(props);

    let slReport =
      this.props.returnData && this.props.returnData.sales_list.length > 0
        ? "yes"
        : null;

    if (this.props.returnData && this.props.returnParams.type_of_report === 2) {
      slReport = "yes";
    }

    let stepIndexLimit = 4;

    if (
      props.returnParams.type_of_report === 1 &&
      props.returnParams.period_type === "year"
    ) {
      stepIndexLimit = 5;
    }

    if (props.returnParams.type_of_report === 2) {
      stepIndexLimit = 2;
    }

    this.state = {
      stepIndex: 1, // 0 - intro in Create Return
      stepIndexLimit,

      data: {
        output_vat: {
          standard_rate: [
            {
              total_sum: props.returnData.total_sum || 0,
              total_vat: props.returnData.total_vat || 0,
            },
          ],
          exempted: [
            {
              total_sum: props.returnData.exempted || 0,
            },
          ],
          reduced_rate: props.returnData.reduced_rate || [],
          not_standard_rate: props.returnData.not_standard_rate || [],
          zero_export: props.returnData.zero_export
            ? [
                {
                  total_sum: props.returnData.zero_export.total_sum,
                },
              ]
            : [],
        },
        output_vat_canada: {},
        input_vat: {
          domestic: [],
          import: [],
          deferred_import_standard: [
            props.returnData?.deferred_import?.standard_rate || [],
          ],
          deferred_import_reduced:
            props.returnData?.deferred_import?.reduced_rate || [],
        },
        intra_community: {
          purchases_standard_rate: [],
          purchases_reduced_rate: [],
          purchases_not_standard_rate: [],
          supplies: [],
        },
        year_info: {
          year_sum: [
            {
              total_sum: 0,
            },
          ],
        },
        a: {
          bank_info: {
            iban: "",
            bic: "",
            bank_name: "",
            account_number: "",
            bank_account_holder: "",
          },
          isClickNext: false,
        },
      },

      newRow: {
        output_vat: {
          standard_rate: {},
          reduced_rate: {},
        },
        input_vat: {
          domestic: {},
          import: {},
        },
        intra_community: {
          purchases_standard_rate: {},
          purchases_reduced_rate: {},
          purchases_not_standard_rate: {},
          supplies: {},
        },
        year_info: {
          year_sum: [{}],
        },
      },

      actionDialogs: {
        reset: { open: false },
        created: { open: false },
        subscription: { open: false },
        editTransaction: { open: false },
      },

      slReport,
      salesListImport: {},

      importFileRows: {
        first: 2,
        last: 9,
      },

      editTransactionSum: null,

      isMergedTransactions: false,
      isUpdatedData: false
    };
    
  }
  

  componentDidMount() {
    this.props.fetchCountriesEU();
    if (this.props.returnParams.type_of_report === 2) {
      this.fetchFields();
    }
  }

  getStepContent(stepIndex) {
    const { returnParams } = this.props;
    const { customer_country_id: countryId } = returnParams;

    switch (stepIndex) {
      case 1:
        return countryId === "CAN" ? this.step2ForCanada() : this.step2();
      case 2:
        return this.step3();
      case 3:
        return countryId === "CAN" ? this.step5() : this.step4();
      case 4:
        return countryId === "CAN" ? () => "" : this.step5();
      default:
        return "";
    }
  }

  renderStepContent(stepIndex) {
    const { returnParams } = this.props;
 
    const {
      customer_country_id: countryId,
      type_of_report,
      reason_for_registration_id,
      period_type,
      customer_state,
      is_digital,
    } = returnParams;
    const isPeriodTypeYear = period_type === "year";

    if (type_of_report === 2) {
      return this.getStepContentForSalesList(stepIndex);
    }

    if (type_of_report === 1) {
      if (customer_state) {
        return this.getStepContentForProvinces(stepIndex);
      }

      if (
        reason_for_registration_id !== 3 ||
        (reason_for_registration_id === 3 && is_digital)
      ) {
        if (isPeriodTypeYear) {
          return this.getStepContentForYear(stepIndex);
        }

        return this.getStepContent(stepIndex);
      }

      return this.getStepCross(stepIndex);
    }
  }

  getStepContentForProvinces(stepIndex) {
    switch (stepIndex) {
      case 1:
        return this.step2ForProvinces();
      case 2:
        return this.step5();
      default:
        return "";
    }
  }

  getStepContentForYear(stepIndex) {
    const { returnParams } = this.props;
    const { customer_country_id: countryId } = returnParams;

    switch (stepIndex) {
      case 1:
        return countryId === "CAN" ? this.step2ForCanada() : this.step2();
      case 2:
        return this.step3();
      case 3:
        return countryId === "CAN" ? this.step6() : this.step4();
      case 4:
        if (countryId === "CAN") {
          return this.step5();
        }
        if (countryId === "DEU") {
          return this.step5ForGermany();
        }
        return this.step6();
      case 5:
        return countryId === "CAN" ? () => "" : this.step5();
      default:
        return "";
    }
  }

  getStepCross(stepIndex) {
    switch (stepIndex) {
      case 1:
        return this.step2();
      case 2:
        return this.step5();
      default:
        return "";
    }
  }

  getStepContentForSalesList(stepIndex) {
    switch (stepIndex) {
      case 1:
        return this.step4();
      case 2:
        return this.step5();
      default:
        return "";
    }
  }

  getCurrencyArr = () => {
    const { returnData } = this.props;
    const currencyArr =
      returnData.currency.toLowerCase() === "eur"
        ? [returnData.currency]
        : [returnData.currency, "EUR"];

    return currencyArr;
  };

  
  mergeTransactions = (arr) => {
    const vatMap = new Map();

    for (const item of arr) {
      if (!vatMap.has(item.vat)) {
        vatMap.set(item.vat, { ...item, ids: [] });
      } else {
        const existing = vatMap.get(item.vat);
        vatMap.set(item.vat, {
          ...existing,
          ids: [...existing.ids, item.id],
        });
      }
    }

    const result = Array.from(vatMap.values());
    return result;
  };

  getManualInputData = () => {
    const { returnData, returnParams, t } = this.props;
    const currency = returnData ? ` (${returnData.currency})` : "";
    const currencyArr = this.getCurrencyArr();
    const countryId = returnParams.customer_country_id;

    const data = {
      output_vat: {
        // step 2 - revenue
        zero_export: [
          // table 0
          {
            name: "total_sum",
            type: "number",
            header: t("createReturns.revExVat"),
            a_code: "a32",
          },
        ],
        exempted: [
          {
            name: "total_sum",
            type: "number",
            header: t("createReturns.revExVat"),
            a_code: "a39",
          },
        ],
        standard_rate: [
          // table 0
          {
            name: "total_sum",
            type: "number",
            header: t("createReturns.revExVat"),
            a_code: "a2",
          },
          {
            name: "total_vat",
            type: "number",
            header: t("createReturns.vatSum"),
            a_code: "a3",
          },
        ],
        reduced_rate: [
          // table 0
          {
            name: "total_sum",
            type: "number",
            header: t("createReturns.revExVat"),
            a_code: "a4",
          },
          {
            name: "total_vat",
            type: "number",
            header: t("createReturns.vatSum"),
            a_code: "a5",
          },
          {
            name: "vat_percent",
            type: "number",
            header: "",
            a_code: "a4_percent",
          },
        ],
        not_standard_rate: [
          // table 0
          {
            name: "total_sum",
            type: "number",
            header: t("createReturns.revExVat"),
            a_code: "a4_n",
          },
          {
            name: "total_vat",
            type: "number",
            header: t("createReturns.vatSum"),
            a_code: "a5_n",
          },
          {
            name: "vat_percent",
            type: "number",
            header: "",
            a_code: "a4_percent_n",
          },
        ],
      },

      input_vat: {
        // step 3
        domestic: [
          // table 1
          {
            name: "date",
            type: "date",
            header: t("createReturns.date"),
            a_code: "a6",
          },
          {
            name: "supplier_vat_n",
            type: "string",
            header: "Supplier VAT No",
            a_code: "a7",
          },
          {
            name: "value_of_supplies",
            type: "number",
            header: "Value of supplies",
            a_code: "a8",
          },
          {
            name: "vat_rate",
            type: "number",
            header: "VAT Rate",
            a_code: "a9_percent",
          },
          {
            name: "vat_sum",
            type: "number",
            header: t("createReturns.vatSum") /* + currency*/,
            a_code: "a9",
          },
        ],
        import: [
          // table 2
          {
            name: "date",
            type: "date",
            header: t("createReturns.date"),
            a_code: "a10",
          },
          {
            name: "supplier_vat_n",
            type: "string",
            header: t("createReturns.numOfCust"),
            a_code: "a11",
          },
          {
            name: "value_of_purchases",
            type: "number",
            header: t("createReturns.valueOfPurch"),
            a_code: "a12",
          },
          {
            name: "vat_rate",
            type: "number",
            header: "VAT Rate",
            a_code: "a13_percent",
          },
          {
            name: "vat_sum_payed_at_customs",
            type: "number",
            header: t("createReturns.vatSumPayed"),
            a_code: "a13",
          },
        ],
      },

      intra_community: {
        // step 4
        purchases_standard_rate: [
          // table 3
          {
            name: "date",
            type: "date",
            header: t("createReturns.date"),
            a_code: "a14",
          },
          {
            name: "invoice_n",
            type: "string",
            header: t("createReturns.numOfInv"),
            a_code: "a15",
          },
          {
            name: "supplier_vat_n",
            type: "string",
            header: t("createReturns.vatNumSupp"),
            a_code: "a16",
          },
          {
            name: "currency",
            type: "select",
            header: t("createReturns.currency"),
            options: currencyArr,
            a_code: "a18_currency",
          },
          {
            name: "value_of_purchases",
            type: "number",
            header: t("createReturns.valueOfPurchOut"),
            a_code: "a17",
          },
          {
            name: "vat_sum",
            type: "number",
            header: t("createReturns.vatSum"),
            a_code: "a18",
          },
          {
            name: "seller_name",
            type: "string",
            header: "Seller name",
            a_code: "a33",
          },
        ],
        purchases_reduced_rate: [
          // table 3
          {
            name: "date",
            type: "date",
            header: t("createReturns.date"),
            a_code: "a24",
          },
          {
            name: "invoice_n",
            type: "string",
            header: t("createReturns.numOfInv"),
            a_code: "a25",
          },
          {
            name: "supplier_vat_n",
            type: "string",
            header: t("createReturns.vatNumSupp"),
            a_code: "a26",
          },
          {
            name: "currency",
            type: "select",
            header: t("createReturns.currency"),
            options: currencyArr,
            a_code: "a28_currency",
          },
          {
            name: "value_of_purchases",
            type: "number",
            header: t("createReturns.valueOfPurchOut"),
            a_code: "a27",
          },
          {
            name: "vat_sum",
            type: "number",
            header: t("createReturns.vatSum"),
            a_code: "a28",
          },
          {
            name: "seller_name",
            type: "string",
            header: "Seller name",
            a_code: "a34",
          },
        ],
        purchases_not_standard_rate: [
          // table 3
          {
            name: "date",
            type: "date",
            header: t("createReturns.date"),
            a_code: "a24_n",
          },
          {
            name: "invoice_n",
            type: "string",
            header: t("createReturns.numOfInv"),
            a_code: "a25_n",
          },
          {
            name: "supplier_vat_n",
            type: "string",
            header: t("createReturns.vatNumSupp"),
            a_code: "a26_n",
          },
          {
            name: "currency",
            type: "select",
            header: t("createReturns.currency"),
            options: currencyArr,
            a_code: "a18_n_currency",
          },
          {
            name: "value_of_purchases",
            type: "number",
            header: t("createReturns.valueOfPurchOut"),
            a_code: "a27_n",
          },
          {
            name: "vat_sum",
            type: "number",
            header: t("createReturns.vatSum"),
            a_code: "a28_n",
          },
          {
            name: "seller_name",
            type: "string",
            header: "Seller name",
            a_code: "a34_n",
          },
        ],
        supplies: [
          // table 4
          /*{ name: 'date', type: 'date', header: 'Date', a_code: 'a19' },*/
          {
            name: "country",
            type: "select",
            header: t("createReturns.country"),
            options: this.props.countriesEU.map((country) => country.name),
            a_code: "a20",
          },
          {
            name: "customer_vat_n",
            type: "string",
            header: t("createReturns.vatNumCust"),
            a_code: "a21",
          },
          {
            name: "value_of_supplies",
            type: "number",
            header: t("createReturns.valueOfPurchOut") + currency,
            a_code: "a22",
          },
          {
            name: "transaction_indicator",
            type: "select",
            header: t("createReturns.goodsOrServ"),
            options: [
              "B2B Goods",
              "Triangulated Goods",
              "B2B Services",
              "Transport of Stock",
              "Return of Stock",
              "New Intended Acquirer",
            ],
            a_code: "a23",
          },
          {
            name: "customer_name",
            type: "string",
            header: "Customer name",
            a_code: "a23_customer_name",
          },
          {
            name: "invoice_date",
            type: "date",
            header: "Invoice date",
            a_code: "a23_invoice_date",
          },
          {
            name: "invoice_number",
            type: "string",
            header: "Invoice number",
            a_code: "a23_vat_inv_number",
          },
        ],
      },
      year_info: {
        // step 5
        year_sum: [
          {
            name: "total_sum",
            type: "number",
            header: t("createReturns.sum"),
            a_code: "a29",
          },
        ],
      },
    };

    const deferredImportEnabled = countryId === "FRA" || countryId === "GBR";

    if (deferredImportEnabled) {
      data.input_vat = {
        ...data.input_vat,

        deferred_import_standard: [
          {
            name: "total_sum",
            type: "number",
            header: "Taxable amount (Euro)",
            a_code: "a35",
          },
          {
            name: "total_vat",
            type: "number",
            header: "VAT",
            a_code: "a36",
          },
        ],
        deferred_import_reduced: [
          {
            name: "total_sum",
            type: "number",
            header: "Taxable amount (Euro)",
            a_code: "a37",
          },
          {
            name: "total_vat",
            type: "number",
            header: "VAT",
            a_code: "a38",
          },
          {
            name: "vat_percent",
            type: "number",
            header: "VAT",
            a_code: "a37_percent",
          },
        ],
      };
    }

    return data;
  };

  styles = {
    tableCell: {
      padding: "1.5rem 0 1.5rem 2rem",
      overflow: "visible",
      width: "20%",
    },
  };

  manualInputData = this.getManualInputData();

  /*countryEUNames = this.props.countriesEU.map((country) => {
        return country.name;
      });*/

  fillReturn = (returnParams, a, final) => {
    const { returnData, t } = this.props;
    const countryId = returnParams.customer_country_id;
    const { data, salesListImport, isMergedTransactions } = this.state;
    // console.log('DATA', data)
    // console.log('returnData', returnData)
    // console.log('final', final)
    // console.log('a', a)
    let params = {};
    if (countryId !== "CAN") {
      const q1 = `${t("createReturns.buyFromSup1")} ${
        this.props.returnData.country.name
      } ${t("createReturns.buyFromSup2")}?`;
      const q2 = t("createReturns.importOut");
      const q3 = `${t("createReturns.buyFromEU1")} ${returnData.country.name}?`;
      const q4 = t("createReturns.supplyB2b");

      const questions = {
        intra_community: [
          this.state[q3] ? this.state[q3][0] : "no",
          this.state[q4] ? this.state[q4][0] : "no",
        ],
      };

      if (returnParams.type_of_report === 1) {
        questions.input_vat = [
          this.state[q1] ? this.state[q1][0] : "no",
          this.state[q2] ? this.state[q2][0] : "no",
        ];
      }
     
      params = {
        ...returnParams,
        a: {
          ...a,
          ...questions,
          final,
        },
      };

      if (
        !_.isEmpty(salesListImport) &&
        !_.isEmpty(salesListImport.sales_list)
      ) {
        
        params.sales_list = salesListImport.sales_list;
      }
    } else {
      
      params = {
        ...returnParams,
        a: {
          ...a,
          final,
        },
      };
    }

    if (isMergedTransactions) {
      params = {
        ...params,
        a: {
          ...params.a,
          sales_list: this.mergeTransactions(returnData?.sales_list || []),
        },
      };
    }
    
    // console.log('params', params)
    this.props
      .createDeclaration(params)
      .then((result) => {
        if (final) {
          this.setState({ addingResult: result, addingError: null });
          this.handleActionDialogsOpen("created", {});
        } else {
          this.setState({ previewResult: result, addingError: null });
          this.handleNext();
        }
      })
      .catch((result) => {
        this.setState({ addingError: result });
      });
  };

  doDeleteRow = (step, table, rowId) => {
    const { data } = this.state;
    data[step][table].map((row, index) => {
      if (row.id === rowId) {
        data[step][table].splice(index, 1);
        this.setState({ data });
      }
    });
  };

  doDeleteSaleListItem = (id, err) => {
    this.props
      .deleteSalesListById(id)
      .then(() => {
        this.props.deleteSalesListItem();
      })
      .catch((response) => {
        this.setState({
          [err]: parseServerError(response),
        });
      });
  };

  doCheckVatNumbers = () => {
    const { salesListImport, returnData } = this.props;
    const { data } = this.state;

    const data1 =
      !_.isEmpty(salesListImport) && salesListImport.sales_list
        ? salesListImport.sales_list.map((item) => ({
            country: item.country_code,
            number: item.vat,
          }))
        : [];
    const data2 = returnData.sales_list.map((item) => ({
      country: item.country_code,
      number: item.vat,
    }));
    const data3 = data.intra_community.supplies.map((item) => ({
      country: this.props.countriesEU.find((cntr) => cntr.name === item.country)
        .code,
      number: item.customer_vat_n,
    }));

    this.props
      .checkVatNumbers({ vat_numbers: [...data1, ...data2, ...data3] })
      .catch((err) => {
        this.setState({ errorCheck: err.error });
      });
  };

  prepareDataAValuesFromTables = (data, step, table) => {
    // get "a" values from tables,  'step' 'table' - items from 'manualInputData'
    const a = {};
 
    this.manualInputData[step][table].map((item) => {
      a[item.a_code] = [];
      if (data[step] && data[step][table]) {
        data[step][table].map((row) => {
          // might be a few rows in table
          const l = a[item?.a_code].length;
          a[item.a_code][l] = row[item.name];
        });
      }
    });
    return a;
  };

  prepareData = (data) => {
    const { returnParams } = this.props;
    const countryId = returnParams.customer_country_id;
    let a = {};
    for (const stepKey in this.manualInputData) {
      // debugger;

      if (stepKey) {
        const step = this.manualInputData[stepKey];
        for (const tableKey in step) {
          if (tableKey) {
            a = {
              ...a,
              ...this.prepareDataAValuesFromTables(data, stepKey, tableKey),
            };
          }
        }
      }
    }
    a.is_report = this.state.slReport === "yes";

    if (countryId === "DEU" && returnParams.period_type === "year") {
      a.bank_info = this.state.data.a.bank_info;
    }
    
    return a;
  };

  handleNext = () => {
    const { returnParams } = this.props;
    const countryId = this.props.returnParams.customer_country_id;
    const { stepIndex } = this.state;
    let maxStep = countryId === "CAN" ? 3 : 4;

    if (
      returnParams.type_of_report === 1 &&
      returnParams.period_type === "year"
    ) {
      maxStep = countryId === "CAN" ? 4 : 5;
    }

    if (returnParams.type_of_report === 2) {
      maxStep = 2;
    }

    if (stepIndex < maxStep) {
      this.setState({
        stepIndex: stepIndex + 1,
        month: false,
        year: false,
      });
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 1) {
      this.setState({
        stepIndex: stepIndex - 1,
      });
    }
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };
  
  updateInput = (step, table, index, vatPercent, value) => {
    const dataCopy = _.cloneDeep(this.state.data); // Глубокое копирование
    // console.log('dataCopy', dataCopy);
    
    dataCopy[step][table][index].total_sum = value;
    if (table !== "year_sum") {
      dataCopy[step][table][index].total_vat = (value * vatPercent) / 100;
    }
    
    if (value < 0 && table !== "zero_export" && step !== "output_vat") {
      dataCopy[step][table][index].err = true;
    }
    if (
      (value >= 0 && dataCopy[step][table][index].err && table !== "zero_export") ||
      step === "output_vat"
    ) {
      dataCopy[step][table][index].err = false;
    }
    
    this.setState({ data: dataCopy });
  };
  
  updateInputVat = (value) => {
    const dataCopy = _.cloneDeep(this.state.data); // Глубокое копирование
    
    dataCopy.output_vat.standard_rate = [{ total_sum: value.total_sum, total_vat: value.total_vat }];
    dataCopy.output_vat.not_standard_rate = value.not_standard_rate;
    dataCopy.output_vat.reduced_rate = value.reduced_rate;
    dataCopy.zero_export = value.zero_export;
    
    this.setState({ data: dataCopy });
  };

  updateCanadaInput = (step, table, index, vatPercent, value) => {
    const data = { ...this.state.data };
    if (!data[step][table]) {
      data[step] = {
        ...data[step],
        [table]: [],
      };
    }

    if (data[step][table][index]) {
      data[step][table][index].total = value;
      data[step][table][index].vat = (vatPercent * value) / 100;
      data[step][table][index].percent = vatPercent;
    } else {
      data[step][table][index] = {
        total: value,
        vat: (vatPercent * value) / 100,
        percent: vatPercent,
      };
    }

    this.setState({ data });
  };

  fetchFields = () => {
    this.props
      .fetchSalesListFields()
      .then((response) => {
        /* headers for file */
        this.setState({ fields: response });

        /* data template */
        const obj = {};
        response.forEach((item, index) => {
          obj[item.name] = index + 1;
        });
        this.setState({ dataFields: obj });
        setTimeout(() => {
          this.setState({ fetchingError: "timeout" });
        }, 3000);
      })
      .catch((response) => {
        this.setState({ fetchingError: response });
      });
  };

  uploadTransactions = () => {
    const { dataFields } = this.state;
    // empty array
    const transactionsArray = new Array(importData[0].length);
    for (let i = 0; i < importData[0].length; i++) {
      transactionsArray[i] = new Array(importData.length);
    }
    // change columns positions according to the "Columns matching"
    let i = 0;
    //for ( const header in headersMatching) {
    for (const header in dataFields) {
      if (header) {
        for (
          let j = this.state.importFileRows.first - 1;
          j < importData[0].length /* - 1*/ &&
          j < this.state.importFileRows.last;
          j++
        ) {
          //let a = importData[headersMatching[header] - 1][j];
          let a = importData[dataFields[header] - 1][j];
          a = a ? String(a).replace(/\s/g, "") : a; // delete spaces
          transactionsArray[j - this.state.importFileRows.first + 1][i] = a;
        }
        i++;
      }
    }
    //transactionsArray.unshift(tableHeaders); // add headers to the array
    const fieldsTitles = this.state.fields.map((field) => field.name);
    transactionsArray.unshift(fieldsTitles /*this.state.fields*/); // add headers to the array
    const worksheet = XLSX.utils.aoa_to_sheet(transactionsArray);
    const CSV = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([CSV], { type: "text/csv;charset=utf-8;" });
    const formData = new FormData();
    formData.append("file", blob, "csvfile");
    this.submitTransaction(formData);
  };

  submitTransaction(csv) {
    const { returnParams } = this.props;
    this.props
      .addSalesList({
        id: returnParams.customer_country_id,
        data: csv,
      })
      .then((salesListImport) => this.setState({ salesListImport }))
      .catch((response) => {
        this.setState({
          importError: parseServerError(response),
        });
      });
  }

  handleFileSelect = () => {
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (ee) => {
          const data = ee.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstsheetname = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstsheetname];
          importData = [];
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const ta = new Array(range.e.r - range.s.r + 1);
            for (let R = range.s.r; R <= range.e.r; ++R) {
              const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
              if (!cell) continue;
              ta[R - range.s.r] = cell.v;
            }
            importData.push(ta);
          }
          this.setState({
            transactionsFile: importData,
          });
        };
        reader.readAsArrayBuffer(file);
        importFileName = file.name;
        this.setState({
          transactionsFileName: importFileName,
        });
      };
    }
  };

  numberInput = (
    step,
    table,
    index,
    name,
    value,
    currency,
    vatPercent,
    onChangeFunc
  ) => {
    const uniqueKey = step + table + name + index;
    const { data } = this.state;
    const { t, returnParams } = this.props;
    const { customer_country_id: countryId, customer_state } = returnParams;
    let initValue = "";

    if (countryId === "CAN" && step !== "year_info") {
      initValue =
        data[step][table] &&
        data[step][table] &&
        data[step][table][index] &&
        (data[step][table][index][name] || data[step][table][index][name] === 0)
          ? data[step][table][index][name]
          : value;
    } else {
      initValue = preciseRound(data[step][table][index][name], 2);
    }

    const isSummReserve = data[index] && data[index].summ_reserve;

    return (
      <TextField
        id={uniqueKey}
        key={uniqueKey}
        name={uniqueKey}
        type='number'
        step={0.01}
        suffix={"  " + currency}
        value={initValue.toString()}
        placeholder='0,00'
        disabled={
          (countryId === "CAN" && step !== "year_info" && !customer_state
            ? name !== "total"
            : name !== "total_sum") || isSummReserve
        }
        error={
          countryId !== "CAN" &&
          data[step][table][index].err &&
          name === "total_sum" &&
          table !== "zero_export"
            ? t("createReturns.negNumberNot")
            : false
        }
        onChange={(v) => {
          if (
            (countryId !== "CAN" && name === "total_sum") ||
            (countryId === "CAN" && name === "total") ||
            (countryId === "CAN" && step === "year_info") ||
            customer_state
          ) {
            onChangeFunc(step, table, index, vatPercent, +v);
          }
        }}
      />
    );
  };

  checkErrors = (data) => {
    const errArray = data.filter((item) => item.err);
    return errArray.length > 0;
  };

  toggleChoiceList = (value, question, step, table) => {
    const data = { ...this.state.data };
    data[step][table] = [];

    if (value === "yes") {
      this.setState({
        [question]: value,
      });
    } else {
      this.setState({
        [question]: value,
        data,
      });
    }
  };

  step2() {
    const { returnParams, returnData, creating, t } = this.props;
    const { data } = this.state;

    const standardErr = this.checkErrors(data.output_vat.standard_rate);
    const reducedErr = !_.isEmpty(data.output_vat.reduced_rate)
      ? this.checkErrors(data.output_vat.reduced_rate)
      : false;
    const notStandartErr = !_.isEmpty(data.output_vat.not_standard_rate)
      ? this.checkErrors(data.output_vat.not_standard_rate)
      : false;
    
    return (
      <div>
        <Step2NewFunctional
          t={t}
          data={data}
          returnData={returnData}
          numberInput={this.numberInput}
          updateInput={this.updateInputVat}
        />
        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button onClick={() => this.handleActionDialogsOpen("reset", {})}>
              {t("createReturns.back")}
            </Button>
            <Button
              disabled={standardErr || reducedErr || notStandartErr || creating}
              primary
              onClick={
                returnParams.type_of_report === 1 &&
                returnParams.reason_for_registration_id === 3
                  ? () => {
                    this.fillReturn(
                      returnParams,
                      {...this.prepareData(data)},
                      false
                    )
                  }
                  : () => {
                      this.handleNext();
                      this.setState({ data });
                    }
              }
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step2ForProvinces() {
    const { returnParams, returnData, creating, t } = this.props;
    const { data } = this.state;

    return (
      <div>
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group condensed>
              <br />
              <Text as="h6" variant="headingMd"  fontWeight="bold">
                {t("createReturns.revExVat")}
              </Text>
              <Text as="h6" variant="headingMd"  fontWeight="bold">PST Amount</Text>
            </FormLayout.Group>

            <FormLayout.Group condensed>
              <span>
                {t("createReturns.rates.standard")}{" "}
                {returnData.vat_percent ? returnData.vat_percent + "%" : ""}
              </span>

              {this.numberInput(
                "output_vat",
                "standard_rate",
                0,
                "total_sum",
                returnData.total_sum,
                returnData.currency,
                returnData.vat_percent,
                this.updateInput
              )}

              {this.numberInput(
                "output_vat",
                "standard_rate",
                0,
                "total_vat",
                returnData.total_vat,
                returnData.currency,
                returnData.vat_percent,
                this.updateInput
              )}
            </FormLayout.Group>

            <FormLayout.Group condensed>
              <span>Exempted</span>

              {this.numberInput(
                "output_vat",
                "exempted",
                0,
                "total_sum",
                returnData.exempted,
                returnData.currency,
                0,
                this.updateInput
              )}
              <TextField
                id={"ex-0"}
                name={"ex-0"}
                type='text'
                step={0.01}
                value={"-"}
                disabled
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button onClick={() => this.handleActionDialogsOpen("reset", {})}>
              {t("createReturns.back")}
            </Button>
            <Button
              disabled={creating}
              primary
              onClick={() => {
                this.fillReturn(
                  returnParams,
                  {...this.prepareData(data)},
                  false
                )
              }
              }
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step2ForCanada() {
    const { returnData, t } = this.props;

    return (
      <div>
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group condensed>
              <Text as="h6" variant="headingMd"  fontWeight="bold">{"State"}</Text>
              <Text as="h6" variant="headingMd"  fontWeight="bold">
                {"Revenue"} ({returnData.currency})
              </Text>
              <Text as="h6" variant="headingMd"  fontWeight="bold">
                {t("createReturns.vatSum")}
              </Text>
            </FormLayout.Group>

            {Object.keys(returnData.state_rate).map((state) => {
              return returnData.state_rate[state].map((rate, ind) => {
                return (
                  <FormLayout.Group condensed key={`${ind}row`}>
                    <Text variant="bodyMd" color="critical">
                      {state} {rate.percent}%
                    </Text>
                    {this.numberInput(
                      "output_vat_canada",
                      state /*'standard_rate'*/,
                      ind,
                      "total",
                      rate.total,
                      returnData.currency,
                      rate.percent,
                      this.updateCanadaInput
                    )}

                    {this.numberInput(
                      "output_vat_canada",
                      state /*'standard_rate'*/,
                      ind,
                      "vat",
                      rate.vat,
                      returnData.currency,
                      rate.percent,
                      this.updateCanadaInput
                    )}
                  </FormLayout.Group>
                );
              });
            })}
          </FormLayout>
        </Card>
        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button onClick={() => this.handleActionDialogsOpen("reset", {})}>
              {t("createReturns.back")}
            </Button>
            <Button primary onClick={() => this.handleNext()}>
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step6() {
    const { returnData, returnParams, creating, t } = this.props;
    const countryId = returnParams.customer_country_id;
    const { data } = this.state;

    return (
      <div>
        <Card
          title={`VAT payments paid for year ${returnParams.period_year}`}
          sectioned
        >
          <FormLayout>
            <FormLayout.Group condensed>
              <span>{t("createReturns.sum")}:</span>

              {this.numberInput(
                "year_info",
                "year_sum",
                0,
                "total_sum",
                data.year_info.year_sum[0].total_sum,
                returnData.currency,
                returnData.vat_percent,
                this.updateInput
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card>

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button disabled={creating} onClick={this.handlePrev}>
              {t("createReturns.back")}
            </Button>
            <Button
              primary
              loading={creating}
              onClick={() =>
                this.fillReturn(
                  returnParams,
                  countryId !== "CAN"
                    ? this.prepareData(data)
                    : {
                        ...this.prepareData(data),
                        state_rate: {
                          ...returnData.state_rate,
                          ...data.output_vat_canada,
                        },
                      },
                  false
                )
              }
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step3() {
    const { returnData, returnParams, t } = this.props;
    const { domesticError, importError, data } = this.state;
    const countryId = returnParams.customer_country_id;
    const deferredImportEnabled = countryId === "FRA" || countryId === "GBR";
    const question = `${t("createReturns.buyFromSup1")} ${
      returnData.country.name
    } ${t("createReturns.buyFromSup2")}?`;
    const question2 =
      countryId === "CAN"
        ? "Did you import your goods from outside Canada?"
        : t("createReturns.importOut");
    const title =
      countryId === "FRA"
        ? "Import VAT paid at customs"
        : t("createReturns.import");

    const domesticBanner = (
      <Banner
        status='critical'
        onDismiss={() => this.setState({ domesticError: false })}
      >
        {domesticError}
      </Banner>
    );

    const importBanner = (
      <Banner
        status='critical'
        onDismiss={() => this.setState({ importError: false })}
      >
        {importError}
      </Banner>
    );

    return (
      <div>
        <Card
          title={`${t("createReturns.purchIn1")} ${
            this.props.returnData.country.name
          } ${t("createReturns.purchIn2")}`}
          sectioned
        >
          <ChoiceList
            key={question}
            name={question}
            title={question}
            choices={[
              { label: t("createReturns.yes"), value: "yes" },
              { label: t("createReturns.no"), value: "no" },
            ]}
            selected={this.state[question] || []}
            onChange={(value) =>
              this.toggleChoiceList(value, question, "input_vat", "domestic")
            }
          />
        </Card>

        {this.state[question] && this.state[question][0] === "yes" && (
          <>
            {domesticError && domesticBanner}
            {this.renderTable("input_vat", "domestic", returnData.purchases)}
          </>
        )}
        {!this.state[question] &&
          returnData.purchases &&
          !_.isEmpty(returnData.purchases) && (
            <>
              {domesticError && domesticBanner}
              {this.renderTable("input_vat", "domestic", returnData.purchases)}
            </>
          )}

        <Card
          title={
            <Box
              display="flex"
              flexDirection="column"
              gap="2"
            >
              <Text fontWeight='semibold' variant='bodyLg'>
                {title}
              </Text>
              {countryId === "FRA" && (
                <Text color='subdued' variant='bodySm'>
                  VAT paid at customs. If you deferred your VAT please fill
                  below.
                </Text>
              )}
            </Box>
          }
          sectioned
        >
          <ChoiceList
            key={question2}
            name={question2}
            title={question2}
            choices={[
              { label: t("createReturns.yes"), value: "yes" },
              { label: t("createReturns.no"), value: "no" },
            ]}
            selected={this.state[question2] || []}
            onChange={(value) =>
              this.toggleChoiceList(value, question2, "input_vat", "import")
            }
          />
        </Card>

        {this.state[question2] && this.state[question2][0] === "yes" && (
          <>
            {importError && importBanner}
            {this.renderTable("input_vat", "import", returnData.import_data)}
          </>
        )}
        {!this.state[question2] &&
          returnData.import_data &&
          !_.isEmpty(returnData.import_data) && (
            <>
              {importError && importBanner}
              {this.renderTable("input_vat", "import", returnData.import_data)}
            </>
          )}

        {deferredImportEnabled && (
          <Card title='Deferred import VAT' sectioned>
            <FormLayout>
              <FormLayout.Group condensed>
                <br />
                <Text as="h6" variant="headingMd"  fontWeight="bold">Taxable amount (Euro)</Text>
                <Text as="h6" variant="headingMd"  fontWeight="bold">VAT</Text>
              </FormLayout.Group>

              <FormLayout.Group condensed>
                <Text>
                  Intrinsic value of imported goods standard rate{" "}
                  {data.input_vat.deferred_import_standard[0].vat_percent}%
                </Text>

                {this.numberInput(
                  "input_vat",
                  "deferred_import_standard",
                  0,
                  "total_sum",
                  0,
                  returnData.currency,
                  20,
                  this.updateInput
                )}

                {this.numberInput(
                  "input_vat",
                  "deferred_import_standard",
                  0,
                  "total_vat",
                  0,
                  returnData.currency,
                  returnData.vat_percent,
                  this.updateInput
                )}
              </FormLayout.Group>

              {data.input_vat.deferred_import_reduced.map((item, index) => {
                return (
                  <FormLayout.Group key={"reduced_rate_row_" + index} condensed>
                    <span>
                      Intrinsic value of imported goods reduced rate{" "}
                      {item.vat_percent + "%"}
                    </span>

                    {this.numberInput(
                      "input_vat",
                      "deferred_import_reduced",
                      index,
                      "total_sum",
                      item.total_sum,
                      returnData.currency,
                      item.vat_percent,
                      this.updateInput
                    )}

                    {this.numberInput(
                      "input_vat",
                      "deferred_import_reduced",
                      index,
                      "total_vat",
                      item.total_vat,
                      returnData.currency,
                      item.vat_percent,
                      this.updateInput
                    )}
                  </FormLayout.Group>
                );
              })}
            </FormLayout>
          </Card>
        )}

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button onClick={this.handlePrev}>{t("createReturns.back")}</Button>
            <Button
              primary
              onClick={() => {
                if (
                  countryId === "CAN" &&
                  returnParams.period_type !== "year"
                ) {
                  this.fillReturn(
                    returnParams,
                    {
                      ...this.prepareData(data),
                      state_rate: {
                        ...returnData.state_rate,
                        ...data.output_vat_canada,
                      },
                    },
                    false
                  );
                } else {
                  this.fetchFields();
                  this.handleNext();
                }
              }}
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step4() {
    // Intra community
    const { returnData, returnParams, checking, creating, t } = this.props;
    const {
      fields,
      dataFields,
      transactionsFile,
      data,
      slReport,
      salesLError,
      salesListImport,
      errorCheck,
      isMergedTransactions,
    } = this.state;
    const question = `${t("createReturns.buyFromEU1")} ${
      returnData.country.name
    }?`;
    const question2 = t("createReturns.supplyB2b");

    const isRenderTable =
      returnData.sales_list?.length > 0 ||
      salesListImport.sales_list?.length > 0 ||
      data?.intra_community?.supplies?.length > 0;

    const columnsReturnData = [
      {
        property: "country",
        header: {
          label: t("createReturns.country"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="2"
                >
                  {rowData.sum === 0 && <AlertIcon stroke={"#B8821B"} />}
                  <Text>{value}</Text>
                </Box>
              );
            },
          ],
        },
      },
      {
        property: "vat_number",
        header: {
          label: t("createReturns.vatNumCust"),
        },
      },
      {
        property: "sum",
        header: {
          label: t("createReturns.valueOfPurchOut"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => `${value} ${returnData.currency}`,
          ],
        },
      },
      {
        property: "service",
        header: {
          label: t("createReturns.goodsOrServ"),
        },
        cell: {
          formatters: [
            (value) => <>{value[0].toUpperCase() + value.slice(1)}</>,
          ],
        },
      },
      {
        property: "item_description",
        header: {
          label: t("createReturns.description"),
        },
        cell: {
          formatters: [
            (value) => (
              <div style={{ whiteSpace: "pre-wrap" }}>
                <Text variant='bodySm'>{value}</Text>
              </div>
            ),
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div className='ButtonOnHover'>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap="2"
                >
                  {rowData.sum === 0 && (
                    <Tooltip content='Edit'>
                      <img
                        src={editIMG}
                        alt='icon edit'
                        onClick={() =>
                          this.handleActionDialogsOpen(
                            "editTransaction",
                            rowData
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip content='Delete'>
                    <img
                      src={deleteIMG}
                      alt='icon delete'
                      onClick={() =>
                        this.doDeleteSaleListItem(rowData.id, "salesLError")
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Box>
              </div>
            ),
          ],
        },
      },
    ];

    const columnsSalesListImport = [
      {
        property: "country",
        header: {
          label: t("createReturns.country"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  gap="2"
                >
                  {rowData.sum === 0 && <AlertIcon stroke={"#B8821B"} />}
                  <Text>{value}</Text>
                </Box>
              );
            },
          ],
        },
      },
      {
        property: "vat",
        header: {
          label: t("createReturns.vatNumCust"),
        },
      },
      {
        property: "sum",
        header: {
          label: t("createReturns.valueOfPurchOut"),
        },
      },
      {
        property: "service",
        header: {
          label: t("createReturns.goodsOrServ"),
        },
        cell: {
          formatters: [
            (value) => <>{value[0].toUpperCase() + value.slice(1)}</>,
          ],
        },
      },
      {
        property: "item_description",
        header: {
          label: t("createReturns.description"),
        },
        cell: {
          formatters: [
            (value) => <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>,
          ],
        },
      },

      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={() => {
                      const importUpdated =
                        this.state.salesListImport.sales_list.filter(
                          (item) => item.id !== rowData.id
                        );
                      this.setState({
                        salesListImport: {
                          ...this.state.salesListImport,
                          sales_list: importUpdated,
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            ),
          ],
        },
      },
    ];

    const columnSupplies = [
      {
        property: "country",
        header: {
          label: t("createReturns.country"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <Box
                  display="flex"
                  gap="2"
                >
                  {rowData.value_of_supplies === 0 && (
                    <AlertIcon stroke={"#B8821B"} />
                  )}
                  <Text>{value}</Text>
                </Box>
              );
            },
          ],
        },
      },
      {
        property: "customer_vat_n",
        header: {
          label: t("createReturns.vatNumCust"),
        },
      },
      {
        property: "value_of_supplies",
        header: {
          label: t("createReturns.valueOfPurchOut"),
        },
      },
      {
        property: "transaction_indicator",
        header: {
          label: t("createReturns.goodsOrServ"),
        },
        cell: {
          formatters: [
            (value) => <>{value[0].toUpperCase() + value.slice(1)}</>,
          ],
        },
      },
      {
        property: "item_description",
        header: {
          label: t("createReturns.description"),
        },
        cell: {
          formatters: [
            (value) => <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>,
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={() => {
                      this.doDeleteRow(
                        "intra_community",
                        "supplies",
                        rowData.id
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            ),
          ],
        },
      },
    ];

    const returnDataSalesList = isMergedTransactions
      ? this.mergeTransactions(returnData?.sales_list || [])
      : returnData?.sales_list || [];

    const sortedRowsReturnData = formatRow(
      returnDataSalesList,
      columnsReturnData
    ); // from return data

    const sortedRowsSalesListImport = formatRow(
      salesListImport?.sales_list || [],
      columnsSalesListImport
    ); // "Add transactions from file"

    const sortedRowsSupplies = formatRow(
      data?.intra_community?.supplies || [],
      columnSupplies
    ); // "Add transactions manually"

    const unitedRows = [
      ...sortedRowsReturnData,
      ...sortedRowsSalesListImport,
      ...sortedRowsSupplies,
    ];

    const tableSupplies = (
      <Card>
        {!_.isEmpty(returnData.sales_list) && (
          <>
            <Card.Section>
             <Box
               display="flex"
               flexDirection="column"
               gap="2"
             >
                <Text color='subdued' variant='bodySm'>
                  {t("createReturns.ifYouFill")}
                </Text>
                <Checkbox
                  checked={isMergedTransactions}
                  onChange={() => {
                    this.setState({
                      isMergedTransactions: !isMergedTransactions,
                    });
                  }}
                  label={
                    <Text variant='bodySm'>
                      Merge warning transactions with same vat number?
                    </Text>
                  }
                />
              </Box>
            </Card.Section>
          </>
        )}
        {salesLError && (
          <Banner
            status='critical'
            onDismiss={() => this.setState({ salesLError: false })}
          >
            {salesLError}
          </Banner>
        )}
        {isRenderTable && (
          <>
            <HighlightableDataTable
              headings={columnsReturnData.map(({ header }) => (
                <Text fontWeight='semibold'>{header.label}</Text>
              ))}
              rows={unitedRows}
              rowClassNameCallback={(value) => ({
                "bg-yellow": /\b0\s/.test(value), // highlight only values that === 0
              })}
            />
          </>
        )}

        {!_.isEmpty(salesListImport) && !_.isEmpty(salesListImport.errors) && (
          <Banner status='critical'>{salesListImport.errors}</Banner>
        )}

        <div style={{ padding: "1rem" }}>
          {errorCheck && (
            <div>
              <Banner
                status='critical'
                onDismiss={() => this.setState({ errorCheck: false })}
              >
                {errorCheck}
              </Banner>
              <br />
            </div>
          )}

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Button
              primary
              loading={checking}
              onClick={() => this.doCheckVatNumbers()}
            >
              Check Vat Numbers
            </Button>

            <ButtonGroup>
              <Button
                onClick={() => this.setState({ addManually: true })}
                disabled={this.state.addManually}
              >
                {t("createReturns.addManually")}
              </Button>
              <Button
                onClick={() => this.setState({ addFromFile: true })}
                disabled={this.state.addFromFile}
              >
                {t("createReturns.addFromFile")}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Card>
    );

    return (
      <div>
        {returnParams.type_of_report === 1 && (
          <Card title={t("createReturns.intraCommAc")} sectioned>
            <ChoiceList
              key={question}
              name={question}
              title={question}
              choices={[
                { label: t("createReturns.yes"), value: "yes" },
                { label: t("createReturns.no"), value: "no" },
              ]}
              selected={this.state[question] || []}
              onChange={(value) => {
                this.toggleChoiceList(
                  value,
                  question,
                  "intra_community",
                  "purchases_standard_rate"
                );
                this.toggleChoiceList(
                  value,
                  question,
                  "intra_community",
                  "purchases_reduced_rate"
                );
                this.toggleChoiceList(
                  value,
                  question,
                  "intra_community",
                  "purchases_not_standard_rate"
                );
              }}
            />
          </Card>
        )}
        <br />

        {!this.state[question] &&
          !_.isEmpty(returnData.intra_community.standard_rate) &&
          returnParams.type_of_report === 1 && (
            <div>
              <p>{t("createReturns.rates.standard")}:</p>
              <br />
              <>
                {this.renderTable("intra_community", "purchases_standard_rate")}
              </>
              <br />
            </div>
          )}

        {!this.state[question] &&
          !_.isEmpty(returnData.intra_community.reduced_rate) &&
          returnParams.type_of_report === 1 && (
            <div>
              <p>{t("createReturns.rates.reduced")}:</p>
              <br />
              <>
                {this.renderTable("intra_community", "purchases_reduced_rate")}
              </>
              <br />
            </div>
          )}

        {this.state[question] &&
          this.state[question][0] === "yes" &&
          returnParams.type_of_report === 1 && (
            <div>
              {this.renderTable(
                "intra_community",
                "purchases_standard_rate",
                null,
                t("createReturns.rates.standard")
              )}

              <br />

              {this.renderTable(
                "intra_community",
                "purchases_reduced_rate",
                null,
                t("createReturns.rates.reduced")
              )}

              <br />
            </div>
          )}

        {returnParams.type_of_report !== 2 && (
          <Card title={"Intra community supplies"} sectioned>
            <ChoiceList
              key={question2}
              name={question2}
              title={question2}
              choices={[
                { label: t("createReturns.yes"), value: "yes" },
                { label: t("createReturns.no"), value: "no" },
              ]}
              selected={
                this.state[question2] ||
                /*returnData.sales_list.length > 0 ? ['yes'] :*/ []
              }
              onChange={(value) => {
                if (value[0] === "no") {
                  this.setState({ slReport: "no" });
                }
                this.toggleChoiceList(
                  value,
                  question2,
                  "intra_community",
                  "supplies"
                );
              }}
            />
          </Card>
        )}

        {returnParams.type_of_report !== 2 && <br />}

        {(returnParams.type_of_report !== 2 &&
          this.state[question2] &&
          this.state[question2][0] === "yes" &&
          tableSupplies) ||
          (returnParams.type_of_report !== 2 &&
            returnData.sales_list.length > 0 &&
            !this.state[question2] &&
            tableSupplies)}

        {returnParams.type_of_report === 2 && tableSupplies}

        {this.state.addManually &&
          this.renderTable("intra_community", "supplies")}

        {this.state.addFromFile && (
          <Card sectioned>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={this.handleFileSelect}>
                  {t("createReturns.addFile")}
                  <input
                    type='file'
                    accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    style={{ display: "none" }}
                    ref={(input) => (this.fileUploadInput = input)}
                  />
                </Button>
                <Button
                  primary
                  disabled={!transactionsFile}
                  onClick={this.uploadTransactions}
                >
                  {t("createReturns.uploadTrans")}
                </Button>
              </div>
              {this.state.transactionsFile && (
                <Badge>
                  {t("createReturns.fileName")}:{" "}
                  {this.state.transactionsFileName}
                </Badge>
              )}
              <Text variant="bodyMd" color="critical">
                1. {t("createReturns.chooseRows")}
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    id='firstRow'
                    label={`${t("createReturns.firstDataRow")}:`}
                    type='number'
                    min='1'
                    max={this.state.importFileRows.last}
                    value={
                      this.state.importFileRows.first
                        ? this.state.importFileRows.first.toString()
                        : ""
                    }
                    onChange={(value) => {
                      const importFileRows = { ...this.state.importFileRows };
                      importFileRows.first = +value;
                      this.setState({ importFileRows });
                    }}
                  />
                  <TextField
                    id='lastRow'
                    label={`${t("createReturns.lastDataRow")}:`}
                    type='number'
                    min={this.state.importFileRows.first || "false"}
                    max='1000'
                    value={
                      this.state.importFileRows.last
                        ? this.state.importFileRows.last.toString()
                        : ""
                    }
                    onChange={(value) => {
                      const importFileRows = { ...this.state.importFileRows };
                      importFileRows.last = +value;
                      this.setState({ importFileRows });
                    }}
                  />
                </FormLayout.Group>
              </FormLayout>
              <Text variant="bodyMd" color="critical">
                2. {t("createReturns.matchEach")}
              </Text>
              {!_.isEmpty(fields) && (
                <ResourceList
                  items={fields}
                  renderItem={(item, k) => {
                    const { title, name } = item;

                    return (
                      <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <div style={{padding: "0.7rem 0 0.7rem 1rem"}}>
                          {title}
                        </div >
                        <div style={{width: "8rem", padding: "0.7rem"}}>
                          <TextField
                            id={"field" + k}
                            type='number'
                            min='1'
                            max={
                              this.state.transactionsFile
                                ? this.state.transactionsFile.length
                                : 1000
                            }
                            disabled={!this.state.transactionsFile}
                            value={
                              dataFields
                                ? dataFields[name].toString()
                                : `${k} + 1`
                            }
                            onChange={(value) => {
                              dataFields[name] = +value; // ~ headersMatching
                              this.setState({dataFields});
                            }}
                          />
                        </div >
                        <div style={{padding: "0.7 1rem"}}>
                          {fields &&
                          dataFields &&
                          importData[dataFields[name] - 1]
                            ? importData[dataFields[name] - 1][
                            this.state.importFileRows.first - 1
                              ]
                            : "-"}
                        </div >
                      </div >
                    );
                  }}
                />
              )}
            </div>
          </Card>
        )}

        {returnParams.type_of_report !== 2 &&
          returnParams.period_type !== "year" && (
            <Card title='Order Sales list report' sectioned>
              <ChoiceList
                name='sales-list'
                choices={[
                  {
                    label: t("createReturns.yes"),
                    value: "yes",
                    disabled: this.state?.[question2]?.[0] === "no",
                  },
                  { label: t("createReturns.no"), value: "no" },
                ]}
                selected={slReport ? [slReport] : []}
                onChange={(values) => this.setState({ slReport: values[0] })}
              />
            </Card>
          )}

        <br />
        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button
              disabled={creating}
              onClick={
                this.props.returnParams.type_of_report !== 2
                  ? this.handlePrev
                  : () => this.handleActionDialogsOpen("reset", {})
              }
            >
              {t("createReturns.back")}
            </Button>
            <Button
              loading={creating}
              disabled={
                (!slReport &&
                  returnParams.type_of_report !== 2 &&
                  returnParams.period_type !== "year") ||
                (unitedRows.some((v) => /\b0\s/.test(v)) &&
                  this.state[t("createReturns.supplyB2b")]?.[0] !== "no") // if some of Value of purchases === 0
              }
              primary
              onClick={() => {
                if (this.props.returnParams.period_type !== "year") {
                  this.fillReturn(
                    this.props.returnParams,
                    this.prepareData(this.state.data),
                    false
                  );
                } else {
                  this.handleNext();
                }
              }}
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step5() {
    const preview = this.state.previewResult.data;
    const previewSL = this.state.previewResult.sales_list;
    const { data, slReport, salesListImport } = this.state;
    const { returnData, user, returnParams, countryName, creating, t} =
      this.props;
    const importSL = !_.isEmpty(salesListImport)
      ? salesListImport.sales_list
      : [];
    
    return (
      <Card sectioned>
        {creating ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {returnParams.type_of_report === 1 && (
              <div>
                <Text variant='bodySm' color='subdued'>
                  {t("createReturns.checkForm")}
                </Text>
                <br />
                <ReturnPreview
                  preview={preview}
                  currency={returnData.final_currency || returnData.currency}
                />
              </div>
            )}

            {slReport === "yes" && (
              <div style={{ paddingBottom: "0.5rem" }}>
                <br />
                <Text variant="headingLg" as="h2">{t("createReturns.salesList")}</Text>
                <Text variant='bodySm' color='subdued'>
                  {user.company.name.toUpperCase()},{" "}
                  {t("createReturns.vatNumber")}:{" "}
                  {user.company.info.default_vat_number}
                </Text>
                <Text variant='bodySm' color='subdued'>{`${t(
                  "createReturns.forThePeriod"
                )} ${returnParams.period} ${returnParams.period_type} ${
                  returnParams.period_year
                }${t("createReturns.for")} ${countryName}`}</Text>
              </div>
            )}

            {slReport === "yes" && (
              <SalesListPreview
                preview={[...previewSL, ...importSL] || []}
                currency={returnData.currency}
              />
            )}
          </React.Fragment>
        )}

        <br />
        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button disabled={creating} onClick={this.handlePrev}>
              {t("createReturns.back")}
            </Button>
            <Button
              primary
              loading={creating}
              onClick={() =>
                this.fillReturn(
                  returnParams,
                  {
                    ...this.prepareData(data),
                    state_rate: {
                      ...returnData.state_rate,
                      ...data.output_vat_canada,
                    },
                  },
                  true
                )
              }
            >
              {t("createReturns.fillTheForm")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </Card>
    );
  }

  step5ForGermany() {
    // Advance payments for Germany
    const { returnData, returnParams, creating, t } = this.props;
    const countryId = returnParams.customer_country_id;
    const { data } = this.state;
    const { account_number, iban, bank_name, bank_account_holder } =
      data.a.bank_info;
    
    const updateBankInfoState = (value, field) => {
      this.setState((state) => ({
        ...state,
        data: {
          ...state.data,
          a: {
            ...state.data.a,
            bank_info: {
              ...state.data.a.bank_info,
              [field]: value,
            },
          },
        },
      }));
    };

    // or account_number or iban required
    const bankInfoItems = [
      {
        label: "IBAN",
        field: "iban",
        error: !account_number && !iban,
        disabled: account_number,
      },
      {
        label: "BIC",
        field: "bic",
      },
      {
        label: "Name of bank",
        field: "bank_name",
        error: !bank_name,
      },
      {
        label: "Account number",
        field: "account_number",
        error: !iban && !account_number,
        disabled: iban,
      },
      {
        label: "Bank account holder",
        field: "bank_account_holder",
        error: !bank_account_holder,
      },
    ];

    const checkIfValid = () => {
      return bankInfoItems.every((item) => !item.error);
    };
 
    return (
      <div>
        <Text variant="headingLg" as="h2">{t("createReturns.advPayments")}</Text>
        <br />
        <Card
          title={`VAT payments paid for year ${returnParams.period_year}`}
          sectioned
        >
          <FormLayout>
            <FormLayout.Group condensed>
              <span>{t("createReturns.sum")}:</span>

              {this.numberInput(
                "year_info",
                "year_sum",
                0,
                "total_sum",
                data.year_info.year_sum[0].total_sum,
                returnData.currency,
                returnData.vat_percent,
                this.updateInput
              )}
            </FormLayout.Group>
          </FormLayout>
        </Card>

        <br />

        <Text variant="headingLg" as="h2">Bank info</Text>
        <br />
        <Card sectioned>
          <FormLayout>
            {bankInfoItems.map(({ label, field, error, disabled }) => (
              <FormLayout.Group condensed>
                <span>{label}</span>
                <TextField
                  id={field}
                  type='string'
                  disabled={disabled}
                  value={data.a.bank_info[field]}
                  onChange={(value) => updateBankInfoState(value, field)}
                  error={!disabled && data.a.isClickNext && error}
                />
              </FormLayout.Group>
            ))}
          </FormLayout>
        </Card>

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button disabled={creating} onClick={this.handlePrev}>
              {t("createReturns.back")}
            </Button>
            <Button
              primary
              loading={creating}
              onClick={() =>
                !checkIfValid()
                  ? this.setState((state) => ({
                      ...state,
                      data: {
                        ...state.data,
                        a: {
                          ...state.data.a,
                          isClickNext: true,
                        },
                      },
                    }))
                  : this.fillReturn(
                      returnParams,
                      countryId !== "CAN"
                        ? this.prepareData(data)
                        : {
                            ...this.prepareData(data),
                            state_rate: {
                              ...returnData.state_rate,
                              ...data.output_vat_canada,
                            },
                          },
                      false
                    )
              }
            >
              {t("createReturns.next")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  renderSubscriptionDialog = () => {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        iconType={"warning"}
        title={t("createReturns.warning")}
        visible={actionDialogs.subscription.open}
        onClose={() => this.handleActionDialogsClose("subscription")}
        content={
          <div
            style={{ display: "flex", alignItems: "center", maxWidth: "400px" }}
          >
            <p>
              {t("createReturns.planOver")} (
              {
                <Link className='Polaris-Link' to='/subscription'>
                  {t("createReturns.selectPlan")}
                </Link>
              }
              ){t("createReturns.contUsing")}
            </p>
            <img src={parrotIMG} alt='lovat_logo' width='20%' />
          </div>
        }
      />
    );
  };

  renderIntraCommunityTable = (
    fields,
    data,
    step,
    table,
    returnData,
    rate,
    err,
    errKey
  ) => {
    const { t } = this.props;

    const columns = fields.map((field) => {
      return {
        property: field.name,
        header: {
          label: field.header,
        },
      };
    });

    const returnDataColumns = [
      {
        property: "transaction_datetime",
        header: {
          label: "Date",
        },
        cell: {
          formatters: [(value) => formatDateForServer(value)],
        },
      },
      {
        property: "seller_vat_number",
        header: {
          label: "Number of invoice",
        },
      },
      {
        property: "currency",
        header: {
          label: "Currency",
        },
      },
      {
        property: "value_of_purchases",
        header: {
          label: "Value of purchases (without VAT)",
        },
      },
      {
        property: "vat",
        header: {
          label: "VAT amount",
        },
      },
      {
        property: "seller_name",
        header: {
          label: "Seller name",
        },
      },
    ];

    returnDataColumns.push({
      property: "",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (value, { rowData }) => {
            return (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={() =>
                      this.doDeleteSaleListItem(
                        rowData.id,
                        `${rate.split("_")[0]}Error`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            );
          },
        ],
      },
    });

    columns.push({
      property: "",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (value, { rowData }) => {
            return (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={() => this.doDeleteRow(step, table, rowData.id)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            );
          },
        ],
      },
    });

    const sortedRows = formatRow(data[step][table], columns);
    const sortedReturnRows =
      returnData.intra_community && returnData.intra_community[rate]
        ? formatRow(returnData.intra_community[rate], returnDataColumns)
        : [];

    return (
      <Card sectioned>
        <Scrollable shadow focusable horizontal>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={[...sortedReturnRows, ...sortedRows]}
            hideScrollIndicator
          />
        </Scrollable>
      </Card>
    );
  };

  renderTable(step, table, dataImport, title = "") {
    const { returnParams, returnData, t } = this.props;
    const { data, newRow, standardError, reducedError, notError } = this.state;
    const fields = this.manualInputData[step][table];
    /* in moment.js quarter index starts from 1, month index - from 0 */
    const period =
      returnParams.period_type === "month"
        ? returnParams.period - 1
        : returnParams.period;

    const prevYearsCount = 3;
    /* as an examle: moment().year(2018).quarter(1).startOf('quarter')*/
    const minDate =
      returnParams.period_type === "year"
        ? moment()
            .year(returnParams.period_year - prevYearsCount)
            .endOf(returnParams.period_type)._d
        : moment()
            .year(returnParams.period_year - prevYearsCount)
            [returnParams.period_type](period)
            .endOf(returnParams.period_type)._d;

    const maxDate =
      returnParams.period_type === "year"
        ? moment()
            .year(returnParams.period_year)
            .endOf(returnParams.period_type)._d
        : moment()
            .year(returnParams.period_year)
            [returnParams.period_type](period)
            .endOf(returnParams.period_type)._d;

    const monthDefault =
      returnParams.period_type === "year"
        ? 0
        : moment()
            .year(returnParams.period_year)
            [returnParams.period_type](period)
            .startOf(returnParams.period_type)
            .month();

    const columns = fields.map((field) => {
      return {
        property: field.name,
        header: {
          label: field.header,
        },
      };
    });

    const columnsDataImport = [
      {
        property: "transaction_datetime",
        header: {
          label: "Date",
        },
        cell: {
          formatters: [(value) => formatDateForServer(value)],
        },
      },
      {
        property: table === "import" ? "vat_inv_number" : "vat_number_of_buyer",
        header: {
          label: "Supplier VAT No",
        },
      },
      {
        property: "value_of_purchases",
        header: {
          label: "Value of supplies",
        },
      },
    ];

    if (
      (table === "purchases_standard_rate" ||
        table === "purchases_reduced_rate" ||
        table === "purchases_not_standard_rate") &&
      step === "intra_community"
    ) {
      columnsDataImport.push({
        property: "currency",
        header: {
          label: "Currency",
        },
      });
    }

    if (table === "domestic" || table === "import") {
      columnsDataImport.push({
        property: "vat_percent",
        header: {
          label: "VAT percent",
        },
      });
    }

    columnsDataImport.push({
      property:
        table === "domestic" || table === "import" ? "vat" : "transaction_sum",
      header: {
        label: "Amount",
      },
    });

    columnsDataImport.push({
      property: "",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (value, { rowData }) => {
            return (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={() =>
                      this.doDeleteSaleListItem(rowData.id, `${table}Error`)
                    }
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            );
          },
        ],
      },
    });

    columns.push({
      property: "",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (value, { rowData }) => {
            return (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={() => this.doDeleteRow(step, table, rowData.id)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            );
          },
        ],
      },
    });

    const sortedRows =
      table !== "supplies" && data?.[step]?.[table]
        ? formatRow(data[step][table], columns)
        : [];

    const sortedDataImport =
      table !== "supplies" && dataImport?.length > 0
        ? formatRow(dataImport, columnsDataImport)
        : [];

    const formAndTable = (
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={(table !== "supplies" && dataImport?.length > 0
            ? columnsDataImport
            : columns
          ).map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={[...sortedRows, ...sortedDataImport]}
          hideScrollIndicator
        />
      </Card>
    );

    const isDisabled = () => {
      const items = newRow[step][table];

      if (step === "intra_community" && table !== "supplies") {
        const filtered = Object.keys(items).filter(
          (key) => key !== "seller_name" && key !== "value_of_supplies"
        );
        return !(
          filtered.every((key) => items[key]) &&
          filtered.length === fields.length - 1
        );
      }
      return !(
        Object.keys(items).every((key) => items[key]) &&
        Object.keys(items).length === fields.length
      );
    };

    return (
      <div style={{ padding: "0.5rem 0px" }}>
        {data[step][table] &&
          !_.isEmpty(data[step][table]) &&
          step !== "intra_community" &&
          table !== "purchases_standard_rate" &&
          formAndTable}

        {data[step][table] &&
          _.isEmpty(data[step][table]) &&
          !_.isEmpty(dataImport) &&
          step !== "intra_community" &&
          table !== "purchases_standard_rate" &&
          formAndTable}

        {data[step][table] &&
          step === "intra_community" &&
          table === "purchases_standard_rate" &&
          this.renderIntraCommunityTable(
            fields,
            data,
            step,
            table,
            returnData,
            "standard_rate",
            standardError,
            "standardError"
          )}

        {data[step][table] &&
          step === "intra_community" &&
          table === "purchases_reduced_rate" &&
          this.renderIntraCommunityTable(
            fields,
            data,
            step,
            table,
            returnData,
            "reduced_rate",
            reducedError,
            "reducedError"
          )}

        {data[step][table] &&
          step === "intra_community" &&
          table === "purchases_not_standard_rate" &&
          this.renderIntraCommunityTable(
            fields,
            data,
            step,
            table,
            returnData,
            "not_standard_rate",
            notError,
            "notError"
          )}

        <Card title={title ? title + ": new record" : `New record`} sectioned>
          <form
            ref='addRow'
            onSubmit={(event) => {
              event.preventDefault();
              newRow[step][table].id = Date.now();
              newRow[step][table].date = formatDateForServer(
                newRow[step][table].date
              );
              data[step][table][data[step][table].length] = newRow[step][table];
              newRow[step][table] = {};
              this.setState({ data, newRow, month: false, year: false });
            }}
          >
            <FormLayout>
              <FormLayout.Group>
                {_.map(fields, (field, index) => {
                  if (
                    field.type === "select" &&
                    newRow[step][table][field.name] === undefined
                  ) {
                    newRow[step][table][field.name] = field.options
                      ? field.options[0]
                      : "";
                  }
                  switch (field.type) {
                    case "date":
                      return (
                        <div key={"col" + field.type + index}>
                          <Datepicker
                            label='Date'
                            id={`${step}${table}dp`}
                            key={field.type + index}
                            name={field.type + index}
                            allowRange={false}
                            disableDatesAfter={maxDate}
                            disableDatesBefore={minDate}
                            start={
                              newRow[step][table].date
                                ? newRow[step][table].date
                                : moment({
                                    year: this.state.year
                                      ? this.state.year
                                      : returnParams.period_year,
                                    month:
                                      this.state.month || this.state.month === 0
                                        ? this.state.month
                                        : monthDefault,
                                  })
                            }
                            value={
                              newRow[step][table].date
                                ? newRow[step][table].date
                                : null
                            }
                            onChange={(value) => {
                              newRow[step][table].date = value;
                              this.setState({
                                newRow,
                              });
                            }}
                            required
                          />
                        </div>
                      );
                    case "select":
                      const options = [
                        /*{ key: `${index}s`, label: '', value: ''}*/
                      ];
                      {
                        _.map(field.options, (item, num) => {
                          options.push({
                            key: `${num}${item}`,
                            label: item,
                            value: item,
                          });
                        });
                      }
                      return (
                        <div key={"col" + field.type + index}>
                          <Select
                            id={Math.random().toString(36).substr(2, 9)}
                            key={field.type + index}
                            name={field.type + index}
                            label={field.header}
                            options={options}
                            onChange={(value) => {
                              newRow[step][table][field.name] = value;
                              this.setState({ newRow });
                            }}
                            value={
                              newRow[step][table][field.name]
                                ? newRow[step][table][field.name]
                                : ""
                            }
                            required
                          />
                        </div>
                      );
                    case "number":
                      const initValue =
                        newRow[step][table][field.name] ||
                        newRow[step][table][field.name] === 0
                          ? preciseRound(newRow[step][table][field.name], 2)
                          : "";

                      let suffix =
                        (table === "purchases_standard_rate" ||
                          table === "purchases_reduced_rate" ||
                          table === "purchases_not_standard_rate") &&
                        step === "intra_community"
                          ? ""
                          : this.props.returnData.currency;
                      if (field.name === "vat_rate") {
                        suffix = "%";
                      }
                      return (
                        <div
                          /*style={{width: '23%'}}*/ key={
                            "col" + field.type + index
                          }
                        >
                          <TextField
                            id={Math.random().toString(36).substr(2, 9)}
                            key={field.type + index}
                            name={field.type + index}
                            label={
                              step === "input_vat" &&
                              table === "domestic" &&
                              field.header === "Value of supplies" ? (
                                <span>
                                  {field.header}
                                  <InfoTooltip content={"Without VAT"} />
                                </span>
                              ) : (
                                field.header
                              )
                            }
                            type='number'
                            step={0.01}
                            min={step === "intra_community" ? "0" : -Infinity}
                            suffix={suffix}
                            value={initValue.toString()}
                            placeholder='0,00'
                            error={
                              newRow[step][table].err &&
                              step === "intra_community"
                                ? t("createReturns.negNumberNot")
                                : false
                            }
                            onChange={(value) => {
                              if (step === "intra_community" && value < 0) {
                                return;
                              }
                              newRow[step][table][field.name] =
                                value !== "" ? +value : "";
                              this.setState({ newRow });
                            }}
                            required
                          />
                        </div>
                      );

                    default: //string
                      return (
                        <TextField
                          id={Math.random().toString(36).substr(2, 9)}
                          key={field.type + index}
                          name={field.type + index}
                          label={field.header}
                          value={
                            newRow[step][table][field.name]
                              ? newRow[step][table][field.name].toString()
                              : ""
                          }
                          onChange={(value) => {
                            newRow[step][table][field.name] = value;
                            this.setState({ newRow });
                          }}
                          required
                        />
                      );
                  }
                })}
                {fields.length % 2 ? <br /> : ""}
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button primary submit disabled={isDisabled()}>
              {t("createReturns.addRecord")}
            </Button>
          </form>
        </Card>
      </div>
    );
  }

  renderResetDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.reset.open}
        onClose={() => this.handleActionDialogsClose("reset")}
        contentOnCenter
        iconType={"warning"}
        description={
          <>
            <p>{t("createReturns.willLose")}</p>
            <p>{t("createReturns.wantComeBack")}</p>
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                this.props.reset();
                this.handleActionDialogsClose("reset");
              }}
            >
              {t("createReturns.toTheFirst")}
            </Button>
          </div>
        }
      />
    );
  }

  renderCreatedReturnDialog() {
    const { actionDialogs, addingResult } = this.state;
    const { t } = this.props;

    const onClose = () => {
      this.handleActionDialogsClose("created");
      this.props.navigate("/vat-returns");
    };

    return (
      addingResult && (
        <Modal
          title={addingResult.data ? "VAT return was created" : "Error"}
          visible={actionDialogs.created.open}
          description={
            <>
              {addingResult.data && (
                <div style={{ maxWidth: 400 }}>
                  <p>{`${t("createReturns.countryCode")}
                ${addingResult.data.customer_country || ""}
                ${t("createReturns.totalSum")}
                ${preciseRound(addingResult.data.total_sum, 2) || "0"}
                ${addingResult.data.currency_id || ""}`}</p>
                  <br />
                  <p>{t("createReturns.receivedReq")}</p>
                </div>
              )}
            </>
          }
          onClose={onClose}
          content={
            <>
              {addingResult.message && (
                <p>
                  {addingResult.message} (
                  {
                    <Link className='Polaris-Link' to='/subscription'>
                      {t("createReturns.changeSubPlan")}
                    </Link>
                  }
                  )
                </p>
              )}
            </>
          }
          footer={
            <Button primary size='large' onClick={onClose}>
              &nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          }
        />
      )
    );
  }

  renderEditTransaction() {
    const { t, isEditingTransaction, returnData } = this.props;
    const { actionDialogs, editTransactionSum } = this.state;

    return (
      actionDialogs.cellData && (
        <Modal
          title={"Edit"}
          description={`Please edit sum for the transaction ${actionDialogs.cellData.country} ${actionDialogs.cellData.vat}`}
          visible={actionDialogs.editTransaction.open}
          onClose={() => this.handleActionDialogsClose("editTransaction")}
          content={
            <>
              <br />
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    value={editTransactionSum}
                    onChange={(value) =>
                      this.setState({ editTransactionSum: value })
                    }
                    label={t("createReturns.valueOfPurchOut")}
                    type='number'
                    suffix={"  " + returnData.currency}
                  />
                </FormLayout.Group>
              </FormLayout>
            </>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button
                onClick={() => {
                  this.handleActionDialogsClose("editTransaction");
                }}
                size='large'
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  const data = {
                    id: actionDialogs.cellData.id,
                    sum: editTransactionSum,
                  };
                  this.props
                    .editTransaction(data)
                    .then(() => {
                      this.props.editSalesList(data);
                      this.handleActionDialogsClose("editTransaction");
                      this.setState({ editTransactionSum: null });
                    })
                    .catch(_.noop);
                }}
                primary
                size='large'
                disabled={editTransactionSum <= 0}
                loading={isEditingTransaction}
              >
                Edit
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  }

  render() {
    const { stepIndex } = this.state;
    const { returnParams, title, t } = this.props;
    const { is_digital, customer_country_id: countryId } = returnParams;

    let steps = [];

    if (
      returnParams.type_of_report === 1 &&
      returnParams.reason_for_registration_id === 3
    ) {
      steps = [
        {
          title: t("createReturns.returnParams"),
          icon: step1IMG,
        },
        {
          title: t("createReturns.outputVat"),
          icon: step1IMG,
        },
        {
          title: t("createReturns.vatDue"),
          icon: step1IMG,
        },
      ];
    }

    if (
      returnParams.type_of_report === 1 &&
      (returnParams.reason_for_registration_id !== 3 ||
        (returnParams.reason_for_registration_id === 3 && is_digital))
    ) {
      if (returnParams.period_type === "year") {
        steps = [
          {
            title: t("createReturns.returnParams"),
            icon: step1IMG,
          },
          {
            title: t("createReturns.outputVat"),
            icon: step1IMG,
          },
          {
            title: t("createReturns.inputVat"),
            icon: step1IMG,
          },
          {
            title:
              countryId === "DEU" ? "Payments" : t("createReturns.advPayments"),
            icon: step1IMG,
          },
          {
            title: t("createReturns.vatDue"),
            icon: step1IMG,
          },
        ];
      } else {
        steps = [
          {
            title: t("createReturns.returnParams"),
            icon: step1IMG,
          },
          {
            title: t("createReturns.outputVat"),
            icon: step1IMG,
          },
          {
            title: t("createReturns.inputVat"),
            icon: step1IMG,
          },
          {
            title: t("createReturns.vatDue"),
            icon: step1IMG,
          },
        ];
      }

      if (countryId !== "CAN") {
        steps.splice(3, 0, {
          title: t("createReturns.intraComm"),
          icon: step1IMG,
        });
      }
    }

    if (returnParams.type_of_report === 2) {
      steps = [
        {
          title: t("createReturns.returnParams"),
          icon: step1IMG,
        },
        {
          title: t("createReturns.intraComm"),
          icon: step1IMG,
        },
        {
          title: t("createReturns.vatDue"),
          icon: step1IMG,
        },
      ];
    }

    if (returnParams.customer_state) {
      steps = [
        {
          title: t("createReturns.returnParams"),
          icon: step1IMG,
        },
        {
          title: t("createReturns.outputVat"),
          icon: step1IMG,
        },
        {
          title: t("createReturns.vatDue"),
          icon: step1IMG,
        },
      ];
    }

    return (
      <div>
        <Layout>
          <Layout.Section secondary>
            {this.state.stepIndex > 0 && (
              <Stepper
                steps={steps}
                activeStep={stepIndex}
                titleFontSize={14}
                activeColor='#E4F3FE'
                completeColor='#216DC5'
                circleFontColor='#212B36'
                defaultTitleColor='#919EAB'
                completeTitleColor='#919EAB'
                activeTitleColor='#212B36'
                completeBorderColor={"#367C41"}
                defaultBorderWidth={4}
                defaultBarColor='#ADADAD'
                size={24}
                circleFontSize={14}
              />
            )}
          </Layout.Section>
          <Layout.Section>
            <div style={{ marginBottom: 102 }}>
              <Text variant='headingMd'>{title}</Text>
              <br />
              {this.renderStepContent(stepIndex)}
            </div>
          </Layout.Section>
        </Layout>

        {this.renderResetDialog()}
        {this.renderCreatedReturnDialog()}
        {this.renderSubscriptionDialog()}
        {this.renderEditTransaction()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  countriesEU: state.other.countriesEU,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
  salesListImport: state.transactions.salesListImport,
  checking: state.declarations.checking,
  checkedNumbers: state.declarations.checkedNumbers,
  isEditingTransaction: state.declarations.isEditingTransaction,
  creatingVatReturn: state.declarations.creatingVatReturn,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclaration: (params) => dispatch(createDeclaration(params)),
    fetchSalesListFields: () => dispatch(fetchSalesListFields()),
    addSalesList: (params) => dispatch(addSalesList(params)),
    deleteSalesListById: (params) => dispatch(deleteSalesListById(params)),
    checkVatNumbers: (params) => dispatch(checkVatNumbers(params)),
    fetchCountriesEU: () => dispatch(fetchCountriesEU()),
    editTransaction: (params) => dispatch(editTransaction(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(GoodsReturn))
);
